import { ArchiveBoxXMarkIcon, ListBulletIcon, PencilSquareIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { PlainInput } from 'components/PlainInput'
import type {
  IExperienceCityCountyTier,
  IExperienceMsaTier,
  IExperienceTier,
  IExperienceTierLimit,
  IStateCitiesCounties,
  IStateMSA,
} from 'config'
import { statesConstant } from 'config/states.constants'
import { LoadingStatus } from 'pages/RateSheetOverview'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Button, ButtonGroup, Checkbox, Input, Select, Tooltip } from 'stories/components'
import { confirm, getPrice3decimal, removeComma } from 'utils'
import { renderHeader } from 'utils/table'

import { checkState, sortAndSearchMsa } from './logic'
import { AddTierModal } from './Modal/AddTierModal'
import { MsaTierValueModal } from './Modal/MsaTierValueModal'
import { StateDataModal } from './Modal/StateDataModal'

interface IProps {
  isTemplate: boolean
  maxLoanAmount: number
  title: string
  tierData: IExperienceTier
  loading: LoadingStatus
  onAdd: (tierName: string, key: string, tierKey?: string) => Promise<boolean>
  onRemove: (tierName: string, key: string, tierKey?: string) => Promise<boolean>
  onUpdate: (originalName: string, tierName: string, key: string, tierKey?: string) => Promise<boolean>
  onSaveTierValues: (
    tierName: string,
    key: string,
    state: string,
    values: string[] | IStateMSA[],
    tierKey?: string,
  ) => Promise<boolean>
  onChangeExperienceLimit: (tierKey: string, tierName: string, limit: IExperienceTierLimit[]) => void
}

const initialMenus = { cityTier: 'City Tiers', countyTier: 'County Tiers', msaTier: 'MSA Tiers' }

const headerList = [
  {
    title: 'Rank',
    key: 'sortRank',
  },
  {
    title: 'MSA Name',
    key: 'sortMsa',
  },
]

const defaultSortOrder = {
  sortRank: 1,
  sortMsa: 1,
}

export const ExperienceTiers = (props: IProps) => {
  const {
    isTemplate,
    loading,
    title,
    maxLoanAmount,
    tierData,
    onAdd,
    onUpdate,
    onRemove,
    onSaveTierValues,
    onChangeExperienceLimit,
  } = props

  const [menus, setMenus] = useState(initialMenus)
  const [selectedMenuKey, setSelectedMenuKey] = useState('')
  const [tierType, setTierType] = useState(menus.cityTier)
  const [tiers, setTiers] = useState<IExperienceCityCountyTier[] | IExperienceMsaTier[]>(tierData?.city || [])
  const [selectedTier, setSelectedTier] = useState<IExperienceCityCountyTier | IExperienceMsaTier>()
  const [modal, setModal] = useState('')
  const [tierKey, setTierKey] = useState('city')
  const [selectedState, setSelectedState] = useState('')
  const [sortOrder, setSortOrder] = useState<Record<string, number>>(defaultSortOrder)
  const [expOptions, setExpOptions] = useState<string[]>([])
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    const experienceOptions: string[] = []

    for (let i = 0; i <= 100; i++) experienceOptions.push(`${i}`)

    setExpOptions(experienceOptions)
  }, [])

  useEffect(() => {
    if (!tierData) {
      setMenus(initialMenus)
      return
    }

    const { city, county, msa } = tierData

    const newMenus = cloneDeep(initialMenus)

    Object.keys(initialMenus).forEach((key) => {
      switch (key) {
        case 'cityTier':
          if (city && !!city.length) {
            newMenus[key] = initialMenus[key] + ` (${city.length})`
          }
          break
        case 'countyTier':
          if (county && !!county.length) {
            newMenus[key] = initialMenus[key] + ` (${county.length})`
          }
          break
        case 'msaTier':
          if (msa && !!msa.length) {
            newMenus[key] = initialMenus[key] + ` (${msa.length})`
          }
          break
      }
    })

    setMenus(newMenus)

    if (selectedMenuKey) setTierType((newMenus as any)[selectedMenuKey])
    else setTierType(newMenus.cityTier)
  }, [tierData])

  useEffect(() => {
    setEdit(false)

    switch (tierType) {
      case menus.cityTier:
        setTiers(tierData?.city)

        if (selectedTier) {
          const newTierData = tierData?.city?.find((item) => item.title === selectedTier.title)

          if (!newTierData) setSelectedTier(tierData?.city && tierData?.city.length > 0 ? tierData?.city[0] : undefined)
          else setSelectedTier(newTierData)
        } else setSelectedTier(tierData?.city && tierData?.city.length > 0 ? tierData?.city[0] : undefined)

        setTierKey('city')
        break
      case menus.countyTier:
        setTiers(tierData?.county)

        if (selectedTier) {
          const newTierData = tierData?.county?.find((item) => item.title === selectedTier.title)

          if (!newTierData)
            setSelectedTier(tierData?.county && tierData?.county.length > 0 ? tierData?.county[0] : undefined)
          else setSelectedTier(newTierData)
        } else setSelectedTier(tierData?.county && tierData?.county.length > 0 ? tierData?.county[0] : undefined)

        setTierKey('county')
        break
      case menus.msaTier:
        setTiers(tierData?.msa)

        if (selectedTier) {
          const newTierData = tierData?.msa?.find((item) => item.title === selectedTier.title)

          if (!newTierData) setSelectedTier(tierData?.msa && tierData?.msa.length > 0 ? tierData?.msa[0] : undefined)
          else setSelectedTier(newTierData)
        } else setSelectedTier(tierData?.msa && tierData?.msa.length > 0 ? tierData?.msa[0] : undefined)

        setTierKey('msa')
        break
      default:
        setTiers([])
    }
  }, [tierType, tierData, menus])

  const onAddTier = async (tierName: string) => {
    if (!tierName.trim()) {
      toast('Tier Name is required', {
        type: 'error',
      })

      return
    }

    let isSameName

    switch (tierType) {
      case menus.cityTier:
        isSameName = tierData?.city?.find((item) => item.title === tierName)
        break
      case menus.countyTier:
        isSameName = tierData?.county?.find((item) => item.title === tierName)
        break
      case menus.msaTier:
        isSameName = tierData?.msa?.find((item) => item.title === tierName)
        break
      default:
        isSameName = ''
        break
    }

    if (isSameName) {
      toast('This Tier Name already exists', {
        type: 'error',
      })

      return
    }

    const res = await onAdd(tierName, 'experience', tierKey)

    if (res) setModal('')
  }

  const onUpdateTier = async (originName: string, tierName: string) => {
    if (originName === tierName.trim()) {
      setModal('')
      return true
    }

    let isSameName

    switch (tierType) {
      case menus.cityTier:
        isSameName = tierData?.city?.find((item) => item.title === tierName)
        break
      case menus.countyTier:
        isSameName = tierData?.county?.find((item) => item.title === tierName)
        break
      case menus.msaTier:
        isSameName = tierData?.msa?.find((item) => item.title === tierName)
        break
      default:
        isSameName = ''
        break
    }

    if (isSameName && originName !== tierName) {
      toast('This Tier Name already exists', {
        type: 'error',
      })

      return false
    }

    const res = await onUpdate(originName, tierName, 'experience', tierKey)

    if (res) setModal('')

    return res
  }

  const onRemoveTier = async () => {
    if (!selectedTier) return

    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this Tier?
        <br />
        <span className="text-gray-600 text-base">Tier Name: {selectedTier.title}</span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const res = await onRemove(selectedTier.title, 'experience', tierKey)

    if (res) setModal('')
  }

  const onSaveMsaValues = async (tierName: string, key: string, values: IStateMSA[]) => {
    const res = await onSaveTierValues(tierName, 'experience', '', values, key)

    if (res) setModal('')
  }

  const onSaveCityCountyValues = async (values: string[]) => {
    if (!selectedTier) return

    const res = await onSaveTierValues(selectedTier.title, 'experience', selectedState, values, tierKey)

    if (res) setModal('')
  }

  const onSort = (key: string, order: number) => {
    setSortOrder({ ...defaultSortOrder, [key]: order })

    if (!selectedTier) return

    const nSelectedTier = cloneDeep(selectedTier)

    let res

    if (key === 'sortMsa') res = sortAndSearchMsa(nSelectedTier.values as IStateMSA[], '', order, NaN)
    if (key === 'sortRank') res = sortAndSearchMsa(nSelectedTier.values as IStateMSA[], '', NaN, order)

    if (res) {
      nSelectedTier.values = res.msa
      setSelectedTier(nSelectedTier)
    }
  }

  const onChangeExperienceRange = (index: number, value: number, startValue = false) => {
    if (!selectedTier) return

    const newData = cloneDeep(selectedTier)

    if (startValue) {
      newData.limit[0].expRange.from = value

      if (newData.limit[0].expRange.from > newData.limit[0].expRange.to)
        newData.limit[0].expRange.to = newData.limit[0].expRange.from
    } else {
      if (newData.limit[index].expRange.from > value) return

      newData.limit[index].expRange.to = value
    }

    if (index < newData.limit.length - 1)
      for (let i = index + 1; i < newData.limit.length; i++) {
        if (newData.limit[i].expRange.from - newData.limit[i - 1].expRange.to !== 1)
          newData.limit[i].expRange.from = newData.limit[i - 1].expRange.to + 1

        if (newData.limit[i].expRange.from > newData.limit[i].expRange.to)
          newData.limit[i].expRange.to = newData.limit[i].expRange.from
      }

    setSelectedTier(newData)
    setEdit(true)
  }

  const addExperienceRangeLimit = () => {
    if (!selectedTier) return

    const newData = cloneDeep(selectedTier)

    newData.limit.push({
      expRange: {
        from: selectedTier.limit.length === 0 ? 0 : selectedTier.limit[selectedTier.limit.length - 1].expRange.to + 1,
        to: selectedTier.limit.length === 0 ? 0 : selectedTier.limit[selectedTier.limit.length - 1].expRange.to + 1,
      },
      minFico: 0,
      maxLoanAmount: maxLoanAmount,
    })

    setSelectedTier(newData)
    setEdit(true)
  }

  const onChangeFicoLoanAmount = (index: number, key: 'minFico' | 'maxLoanAmount', value: string) => {
    if (!selectedTier) return

    const newValue = removeComma(value)

    if (newValue < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(selectedTier)

    if (newData.limit[index][key] === newValue) return

    newData.limit[index][key] = newValue

    setSelectedTier(newData)
    setEdit(true)
  }

  const removeLimit = async (index: number) => {
    if (!selectedTier) return

    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this row?
        <br />
        <span className="text-gray-600 text-base">
          Experience Range: {selectedTier.limit[index].expRange.from} - {selectedTier.limit[index].expRange.to}
        </span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const newData = cloneDeep(selectedTier)

    newData.limit.splice(index, 1)

    for (let i = 1; i < newData.limit.length; i++) {
      if (newData.limit[i].expRange.from - newData.limit[i - 1].expRange.to !== 1)
        newData.limit[i].expRange.from = newData.limit[i - 1].expRange.to + 1

      if (newData.limit[i].expRange.from > newData.limit[i].expRange.to)
        newData.limit[i].expRange.to = newData.limit[i].expRange.from
    }

    setSelectedTier(newData)
    setEdit(true)
  }

  const renderTierContent = useMemo(() => {
    if (!selectedTier)
      return (
        <div className="w-full flex justify-center items-center">
          <div className="flex flex-col items-center gap-1">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Tiers</span>
          </div>
        </div>
      )

    switch (tierType) {
      case menus.cityTier:
      case menus.countyTier:
        return (
          <div>
            <div className="mb-4">
              {isTemplate && (
                <div className="flex justify-end px-2 mb-1">
                  <Tooltip message="Edit Tier Name">
                    <div
                      className="text-shade-blue p-1 transition-all duration-200 hover-shadow1 cursor-pointer rounded mr-2"
                      onClick={() => setModal('editTier')}
                    >
                      <PencilSquareIcon className="w-5 h-5" />
                    </div>
                  </Tooltip>

                  <Tooltip message="Remove Tier">
                    <div
                      className="p-1 hover-shadow1 transition-all duration-200 cursor-pointer rounded"
                      onClick={() => onRemoveTier()}
                    >
                      <TrashIcon className="w-5 h-5 text-red-600" />
                    </div>
                  </Tooltip>
                </div>
              )}

              <div className="flex flex-wrap gap-4 p-3 border rounded">
                {statesConstant.map((state, index) => {
                  const values = selectedTier.values as IStateCitiesCounties

                  return (
                    <div key={index} className="flex items-center gap-1 w-[65px]">
                      <Checkbox
                        id={index.toString()}
                        title={state}
                        value={checkState(values, state)}
                        color="gray"
                        className="flex items-center w-[45px]"
                        onClick={() => {
                          setSelectedState(state)
                          setModal('showStateData')
                        }}
                      />

                      {values[state] && values[state].length > 0 && (
                        <div className=" text-sm">({values[state].length})</div>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )
      case menus.msaTier:
        return (
          <div>
            {isTemplate && (
              <div className="flex justify-end px-2 mb-1">
                <Tooltip message="Edit Tier Name">
                  <div
                    className="text-shade-blue transition-all duration-200 p-1 hover-shadow1 cursor-pointer rounded mr-2"
                    onClick={() => setModal('editTier')}
                  >
                    <PencilSquareIcon className="w-5 h-5" />
                  </div>
                </Tooltip>

                <Tooltip message="Select MSAs">
                  <div
                    className="text-shade-blue transition-all duration-200 p-1 hover-shadow1 cursor-pointer rounded mr-2"
                    onClick={() => setModal('editTierValue')}
                  >
                    <ListBulletIcon className="w-5 h-5" />
                  </div>
                </Tooltip>

                <Tooltip message="Remove Tier">
                  <div
                    className="p-1 hover-shadow1 transition-all duration-200 cursor-pointer rounded"
                    onClick={() => onRemoveTier()}
                  >
                    <TrashIcon className="w-5 h-5 text-red-600" />
                  </div>
                </Tooltip>
              </div>
            )}

            <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6 mb-4">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-3 py-3">
                    No
                  </th>
                  {headerList.map((item: Record<string, string>, index: number) => {
                    return renderHeader({
                      title: item.title,
                      key: item.key,
                      index: index,
                      onSort: (key: string, order: number) => onSort(key, order),
                      sortable: true,
                      sortOrder: sortOrder[item.key],
                    })
                  })}
                  <th scope="col" className="px-3 py-3">
                    States MSA
                  </th>
                </tr>
              </thead>

              <tbody>
                {Array.isArray(selectedTier.values) &&
                  selectedTier.values.map((item, index) => {
                    const statesMsa = item.states.join('-') + ' MSA'
                    return (
                      <tr className={`border-b ${index % 2 ? 'bg-slate-50' : 'bg-white'}`} key={index}>
                        <td scope="row" className="px-3 py-3">
                          {index + 1}
                        </td>
                        <td scope="row" className="px-3 py-3">
                          {item.msa.ranking}
                        </td>
                        <td scope="row" className="px-3 py-3">
                          {item.msa.name}
                        </td>
                        <td scope="row" className="px-3 py-3">
                          {statesMsa}
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        )
      default:
        return <></>
    }
  }, [tierType, selectedTier, tierData])

  const renderTiers = useMemo(() => {
    return (
      <div className="flex items-center flex-wrap mt-3 mb-2 px-2">
        {tiers &&
          tiers.length > 0 &&
          tiers.map((tier, index: number) => (
            <Button
              color={`${selectedTier?.title === tier.title ? 'blue' : 'gray'}`}
              className="text-[14px] pt-[6px] pb-[6px] transition-all duration-200"
              key={index}
              onClick={() => {
                setSelectedTier(tier)
                setEdit(false)
              }}
            >
              {tier.title}
            </Button>
          ))}

        {isTemplate && (
          <Button
            color="gray"
            className="pt-[7px] pb-[7px] transition-all duration-200"
            onClick={() => setModal('addTier')}
          >
            <PlusIcon className="w-5 h-5" />
          </Button>
        )}
      </div>
    )
  }, [tiers, selectedTier])

  const renderExperienceRangeLimit = useMemo(() => {
    if (!selectedTier) return <></>

    if (!selectedTier.limit.length)
      return (
        <div className="w-full flex justify-center items-center mt-6">
          <div className="flex flex-col items-center gap-1">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Limits</span>
          </div>
        </div>
      )

    return (
      <table className="w-full text-sm text-center">
        <thead className="bg-gray-100">
          <tr>
            <th className="border px-3 py-2 w-5/12 whitespace-nowrap">Experience</th>
            <th className="border px-3 py-2 w-2/12 whitespace-nowrap">Min FICO</th>
            <th className="border px-3 py-2 w-3/12 whitespace-nowrap">Max Loan Amount</th>
            {isTemplate && <th className="border px-3 py-2 w-2/12 whitespace-nowrap">Action</th>}
          </tr>
        </thead>
        <tbody>
          {selectedTier.limit.map((item, index) => (
            <tr key={index}>
              <td className="border py-1">
                <div className="flex items-center justify-center gap-2">
                  {index === 0 ? (
                    <Select
                      id="from"
                      options={expOptions}
                      value={item.expRange.from.toString()}
                      className="w-[100px] mb-[-16px]"
                      readOnly={!isTemplate}
                      onChange={(v) => onChangeExperienceRange(index, Number(v), true)}
                    />
                  ) : (
                    <Input
                      value={item.expRange.from.toString()}
                      className="w-[100px] mb-[-16px]"
                      disabled={true}
                      onChange={() => {}}
                    />
                  )}

                  <span>―</span>

                  <Select
                    id="to"
                    options={expOptions}
                    value={item.expRange.to.toString()}
                    readOnly={!isTemplate}
                    className="w-[100px] mb-[-16px]"
                    onChange={(v) => onChangeExperienceRange(index, Number(v))}
                  />
                </div>
              </td>

              <td className="border">
                <div className="flex justify-center">
                  <PlainInput
                    value={item.minFico}
                    origin={item.minFico}
                    content={getPrice3decimal(item.minFico)}
                    disabled={!isTemplate}
                    onChange={(value: string) => onChangeFicoLoanAmount(index, 'minFico', value)}
                    className="w-full"
                  />
                </div>
              </td>
              <td className="border">
                <div className="flex justify-center">
                  <PlainInput
                    value={item.maxLoanAmount}
                    origin={item.maxLoanAmount}
                    content={getPrice3decimal(item.maxLoanAmount)}
                    disabled={!isTemplate}
                    prefix="$"
                    onChange={(value: string) => onChangeFicoLoanAmount(index, 'maxLoanAmount', value)}
                    className="w-full"
                  />
                </div>
              </td>

              {isTemplate && (
                <td className="border">
                  <div className="flex justify-center">
                    <div
                      className="w-fit p-1 transition-all duration-200 hover:cursor-pointer hover-shadow1 rounded"
                      onClick={() => removeLimit(index)}
                    >
                      <TrashIcon className="w-4 h-4 hover:cursor-pointer text-red-700" />
                    </div>
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    )
  }, [selectedTier])

  const onChangeMenuItem = (value: string) => {
    const selectedKey = Object.keys(menus).find((key) => (menus as any)[key] === value)

    if (selectedKey) setSelectedMenuKey(selectedKey)

    setTierType(value)
  }

  const renderMenus = useMemo(() => {
    return <ButtonGroup title={Object.values(menus)} value={tierType} onChange={onChangeMenuItem} />
  }, [menus, tierType])

  return (
    <div className="relative">
      <LayoutLoading
        show={[
          LoadingStatus.ADD_TIER,
          LoadingStatus.REMOVE_TIER,
          LoadingStatus.TIER_VALUES,
          LoadingStatus.UPDATE_EXPERIENCE_TIER_LIMIT,
        ].includes(loading)}
      />
      <div className="text-lg font-variation-settings-600 mb-3">{title}</div>

      {renderMenus}

      {renderTiers}

      <div className="px-2">
        {renderTierContent}

        {isTemplate && (
          <div className={`flex items-center mb-2 ${edit ? 'justify-between' : 'justify-end'}`}>
            {edit && (
              <Button
                loading={LoadingStatus.UPDATE_EXPERIENCE_TIER_LIMIT === loading}
                onClick={() => {
                  if (!selectedTier) return

                  onChangeExperienceLimit(tierKey, selectedTier.title, selectedTier.limit)
                }}
                className="pt-1.5 pb-1.5"
              >
                Save
              </Button>
            )}

            {selectedTier && (
              <div
                className="flex items-center gap-2 cursor-pointer w-fit text-shade-blue transition-all duration-200 hover:border-b hover:border-b-shade-blue pr-1 h-6"
                onClick={addExperienceRangeLimit}
              >
                <PlusIcon className="w-4 h-4" />
                <span>Add LTV Limit</span>
              </div>
            )}
          </div>
        )}

        {renderExperienceRangeLimit}
      </div>

      {['addTier', 'editTier'].includes(modal) && (
        <AddTierModal
          name={modal === 'editTier' ? selectedTier?.title || '' : ''}
          isOpen={['addTier', 'editTier'].includes(modal)}
          loading={loading}
          onClose={() => setModal('')}
          onSave={onAddTier}
          onUpdate={onUpdateTier}
        />
      )}

      {modal === 'showStateData' && selectedTier && tierKey && (
        <StateDataModal
          isTemplate={isTemplate}
          state={selectedState}
          title={tierKey}
          tierData={selectedTier as IExperienceCityCountyTier}
          allTiers={tiers as IExperienceCityCountyTier[]}
          loading={loading}
          isOpen={modal === 'showStateData'}
          onClose={() => setModal('')}
          onSubmit={onSaveCityCountyValues}
        />
      )}

      {modal === 'editTierValue' && selectedTier && (
        <MsaTierValueModal
          isTemplate={isTemplate}
          allTiers={tiers as IExperienceMsaTier[]}
          tierData={selectedTier as IExperienceMsaTier}
          loading={loading}
          isOpen={modal === 'editTierValue'}
          onClose={() => setModal('')}
          onSubmit={onSaveMsaValues}
        />
      )}
    </div>
  )
}
