import {
  bankruptcyOptions,
  citizenshipOptions,
  CommercialProdutTypes,
  escrowTypesOptions,
  foreclosuresOptions,
  InputType,
  loanTermOptions,
  mortgageLatesOptions,
  prePaymentPenaltyTermInMonthsOptions,
} from 'config'
import { getPrice2decimal, getPrice3decimal, InputValidate, isEmpty, removeComma } from 'utils'

import { fieldsByTransactionTypeAndProductTypeConstant } from './constants'

export const calculateLTV = (loanFields: Record<string, InputType>) => {
  let { constructionReserve, proposedLoanAmount, secondLien, minPurchasePriceAppraisedValue } =
    analyzeLoanStructureValue(loanFields)

  const LTV = (100 * (proposedLoanAmount - constructionReserve)) / minPurchasePriceAppraisedValue
  const CLTV = (100 * (proposedLoanAmount + secondLien - constructionReserve)) / minPurchasePriceAppraisedValue

  return {
    LTV: LTV.toFixed(3),
    CLTV: CLTV.toFixed(3),
  }
}

export function calculateLimit(loanFields: Record<string, InputType>) {
  let LTV = 0
  let CLTV = 0
  let LTP = 0
  let LTC = 0
  let ARV_LTV = 0
  const { transactionType } = loanFields

  let propertyPurchasePrice = removeComma(loanFields.propertyPurchasePrice.value)
  let asIsValue = removeComma(loanFields.asIsValue.value)
  let constructionReserve = removeComma(loanFields.constructionReserve.value)
  let proposedLoanAmount = removeComma(loanFields.proposedLoanAmount.value)
  let afterRepairValue = removeComma(loanFields.afterRepairValue.value)
  let rehabBudget = removeComma(loanFields.rehabBudget.value)
  let secondLien = removeComma(loanFields.secondLien.value)

  const visibleLoanStructureFields = visibleLoansLogic(loanFields)

  if (!visibleLoanStructureFields.includes('propertyPurchasePrice')) propertyPurchasePrice = 0
  if (!visibleLoanStructureFields.includes('asIsValue')) asIsValue = 0
  if (!visibleLoanStructureFields.includes('constructionReserve')) constructionReserve = 0
  if (!visibleLoanStructureFields.includes('proposedLoanAmount')) proposedLoanAmount = 0
  if (!visibleLoanStructureFields.includes('afterRepairValue')) afterRepairValue = 0
  if (!visibleLoanStructureFields.includes('rehabBudget')) rehabBudget = 0
  if (!visibleLoanStructureFields.includes('secondLien')) secondLien = 0

  let minPurchasePriceAppraisedValue = asIsValue

  if (transactionType.value === 'Purchase') {
    if (asIsValue === 0) minPurchasePriceAppraisedValue = propertyPurchasePrice
    else if (propertyPurchasePrice === 0) minPurchasePriceAppraisedValue = asIsValue
    else minPurchasePriceAppraisedValue = Math.min(propertyPurchasePrice, asIsValue)
  }

  LTV = (100 * (proposedLoanAmount - constructionReserve)) / minPurchasePriceAppraisedValue
  CLTV = (100 * (proposedLoanAmount + secondLien - constructionReserve)) / minPurchasePriceAppraisedValue
  ARV_LTV = (100 * proposedLoanAmount) / afterRepairValue
  LTC = (100 * proposedLoanAmount) / (minPurchasePriceAppraisedValue + rehabBudget)
  LTP = (100 * (proposedLoanAmount - constructionReserve)) / propertyPurchasePrice

  return {
    LTV: LTV.toFixed(3),
    CLTV: CLTV.toFixed(3),
    ARV_LTV: ARV_LTV.toFixed(3),
    LTC: LTC.toFixed(3),
    LTP: LTP.toFixed(3),
  }
}

const analyzeLoanStructureValue = (loanFields: Record<string, InputType>) => {
  let visibleFields =
    fieldsByTransactionTypeAndProductTypeConstant[loanFields.productType.value]?.[loanFields.transactionType.value] ||
    []
  let propertyPurchasePrice = removeComma(loanFields.propertyPurchasePrice.value)
  let asIsValue = removeComma(loanFields.asIsValue.value)
  let constructionReserve = removeComma(loanFields.constructionReserve.value)
  let proposedLoanAmount = removeComma(loanFields.proposedLoanAmount.value)
  let afterRepairValue = removeComma(loanFields.afterRepairValue.value)
  let rehabBudget = removeComma(loanFields.rehabBudget.value)
  let lienPayoff = removeComma(loanFields.lienPayoff.value)
  let closingCostEstimate = removeComma(loanFields.closingCostEstimate.value)
  let interestReserve = removeComma(loanFields.interestReserve.value)
  let secondLien = removeComma(loanFields.secondLien.value)

  if (visibleFields.indexOf('propertyPurchasePrice') === -1) propertyPurchasePrice = 0
  if (visibleFields.indexOf('asIsValue') === -1) asIsValue = 0
  if (visibleFields.indexOf('constructionReserve') === -1) constructionReserve = 0
  if (visibleFields.indexOf('proposedLoanAmount') === -1) proposedLoanAmount = 0
  if (visibleFields.indexOf('afterRepairValue') === -1) afterRepairValue = 0
  if (visibleFields.indexOf('rehabBudget') === -1) rehabBudget = 0
  if (visibleFields.indexOf('secondLien') === -1) secondLien = 0

  let minPurchasePriceAppraisedValue = asIsValue
  if (loanFields.transactionType.value === 'Purchase') {
    if (asIsValue === 0) minPurchasePriceAppraisedValue = propertyPurchasePrice
    else if (propertyPurchasePrice === 0) minPurchasePriceAppraisedValue = asIsValue
    else minPurchasePriceAppraisedValue = Math.min(propertyPurchasePrice, asIsValue)
  }
  return {
    propertyPurchasePrice,
    asIsValue,
    constructionReserve,
    proposedLoanAmount,
    afterRepairValue,
    rehabBudget,
    lienPayoff,
    closingCostEstimate,
    interestReserve,
    secondLien,
    minPurchasePriceAppraisedValue,
  }
}

export const fromToNumberOptions = (start: number, end: number) => {
  const rlt: string[] = []

  for (let i = start; i <= end; i++) {
    rlt.push(i.toString())
  }

  return rlt
}

export const visibleLoansLogic = (fields: Record<string, InputType>): string[] => {
  let visibleFields = ['loanName', 'productType', 'transactionType']

  if (fields.productType.value && fields.transactionType.value) {
    const definedVisibleFields =
      fieldsByTransactionTypeAndProductTypeConstant[fields.productType.value][fields.transactionType.value]
    visibleFields = [...visibleFields, ...definedVisibleFields]

    if (fields.propertyType.value === 'Condo') {
      visibleFields.push('condoType')
    }

    if (fields.propertyState.value === 'NY' && fields.transactionType.value === 'Refinance')
      visibleFields.push('isCEMATransaction')

    if (removeComma(fields.secondLien.value) > 0) visibleFields.push('secondLienPayment')

    if (visibleFields.includes('experience') && fields.experience.value === '5+ Transactions')
      visibleFields.push('howManyExperiences')
  }

  return visibleFields
}

export const isNonDSCRProduct = (productType: string) => {
  return 'DSCR' !== productType
}

export const isLtvAdjustments = (fields: string[]) => {
  return fields.includes('ltvAdjustment') || fields.includes('aivLtvAdjustment')
}

export const estimatedClosingAmountBorrower = (loanFields: Record<string, InputType>) => {
  let value = 0,
    valueWithoutConstructionCalc = 0,
    closingTooltip = '',
    closingMaxRehabBudget = 0,
    closingError = '',
    rehabFinancedPercent = '',
    totalRehabFinancedPercent = '',
    totalRehabFinancedTooltip = ''

  let visibleFields =
    fieldsByTransactionTypeAndProductTypeConstant[loanFields.productType.value]?.[loanFields.transactionType.value]
  if (!visibleFields) visibleFields = []

  if (loanFields.transactionType.value === 'Refinance') {
    valueWithoutConstructionCalc =
      removeComma(loanFields.proposedLoanAmount.value) -
      removeComma(loanFields.lienPayoff.value) -
      removeComma(loanFields.closingCostEstimate.value) -
      removeComma(loanFields.interestReserve.value)
    value = valueWithoutConstructionCalc - removeComma(loanFields.constructionReserve.value)

    closingTooltip = `Proposed Loan Amount<br/>- Lien Payoff<br/>- Closing Cost Estimate<br/>- Interest Reserve<br/>- Construction Amount Financed`

    if (!visibleFields.includes('lienPayoff')) {
      value += removeComma(loanFields.lienPayoff.value)
      closingTooltip = closingTooltip.replace('<br/>- Lien Payoff', '')
    }
    if (!visibleFields.includes('constructionReserve')) {
      value += removeComma(loanFields.constructionReserve.value)
      closingTooltip = closingTooltip.replace('<br/>- Construction Amount Financed', '')
    }
    if (!visibleFields.includes('interestReserve')) {
      value += removeComma(loanFields.interestReserve.value)
      closingTooltip = closingTooltip.replace('<br/>- Interest Reserve', '')
    }

    if (visibleFields.includes('rehabBudget')) {
      closingMaxRehabBudget = (removeComma(loanFields.rehabBudget.value) * 35) / 100
      if (value > closingMaxRehabBudget) {
        closingError = `May not exceed 35% 'Construction Budget' which are $${getPrice2decimal(closingMaxRehabBudget)}`
      }
    }
  } else {
    value =
      removeComma(loanFields.propertyPurchasePrice.value) -
      removeComma(loanFields.proposedLoanAmount.value) +
      removeComma(loanFields.closingCostEstimate.value) +
      removeComma(loanFields.interestReserve.value) +
      removeComma(loanFields.constructionReserve.value)
    closingTooltip =
      'Property Purchase Price<br/>- Proposed Loan Amount<br/>+ Closing Cost Estimate<br/>+ Interest Reserve<br/>+ Construction Amount Financed'

    if (!visibleFields.includes('constructionReserve')) {
      value -= removeComma(loanFields.constructionReserve.value)
      closingTooltip = closingTooltip.replace('<br/>+ Construction Amount Financed', '')
    }
    if (!visibleFields.includes('interestReserve')) {
      value -= removeComma(loanFields.interestReserve.value)
      closingTooltip = closingTooltip.replace('<br/>+ Interest Reserve', '')
    }
  }
  if (visibleFields.includes('rehabBudget') && removeComma(loanFields.rehabBudget.value) > 0) {
    rehabFinancedPercent = (
      (removeComma(loanFields.constructionReserve.value) * 100) /
      removeComma(loanFields.rehabBudget.value)
    ).toFixed(2)
    const closingCost = loanFields.transactionType.value === 'Refinance' ? (value < 0 ? 0 : value) : 0
    totalRehabFinancedTooltip = `'Construction Amount Financed'${
      closingCost > 0 ? " + 'Estimated Closing Amount to Borrower'" : ''
    }/<br/>'Construction Budget'`
    totalRehabFinancedPercent = (
      ((removeComma(closingCost) + removeComma(loanFields.constructionReserve.value)) * 100) /
      removeComma(loanFields.rehabBudget.value)
    ).toFixed(2)
  }
  return {
    rehabFinancedPercent,
    totalRehabFinancedPercent,
    totalRehabFinancedTooltip,
    value,
    valueWithoutConstructionCalc,
    closingTooltip,
    closingMaxRehabBudget,
    closingError,
  }
}

export const propertyTypeNumberUnitInputLogic = (propertyType: string, input: any) => {
  switch (propertyType) {
    case 'SFR-Detached':
    case 'SFR-Attached':
    case 'Industrial':
      input.inputType = 'select'
      input.options = fromToNumberOptions(1, 1)
      input.hasDefaultOption = true
      break
    case 'Condo':
    case 'PUD':
    case 'Automotive':
    case 'Modular':
    case 'Manufactured':
      input.inputType = 'select'
      input.options = fromToNumberOptions(1, 4)
      input.hasDefaultOption = true
      break

    case 'Office':
    case 'Warehouse':
      input.inputType = 'select'
      input.options = fromToNumberOptions(1, 100)
      input.hasDefaultOption = true
      break
    case '2-4 Units':
      input.inputType = 'select'
      input.options = fromToNumberOptions(2, 4)
      input.hasDefaultOption = true
      break

    default: {
      input.inputType = 'text'
      break
    }
  }
  return input
}

export const specificLoanFieldsValidate = (loanField: Record<string, InputType>) => {
  //  The After Repair Value (ARV) must be no less than 115% of the total costs (lesser of the as is value / purchase price + rehab budget)
  let { afterRepairValue, rehabBudget, minPurchasePriceAppraisedValue } = analyzeLoanStructureValue(loanField)
  const minAfterRepairValue = Math.ceil(1.15 * (minPurchasePriceAppraisedValue + rehabBudget))
  if (0 < afterRepairValue && afterRepairValue < minAfterRepairValue) {
    loanField.afterRepairValue.error = `The 'After Repair Value' must be no less than 115% of the Total Costs which are $${getPrice2decimal(
      minAfterRepairValue,
    )}`
  }

  return loanField
}

export const loanFieldsValidateAll = (loanField: Record<string, InputType>) => {
  Object.keys(loanField).map((key) => {
    if (loanField.isNoScore.value && key === 'estimatedCreditScore') return
    loanField[key].error = InputValidate({ ...loanField[key] })
  })

  loanField.numberOfUnits = propertyTypeNumberUnitInputLogic(loanField.propertyType.value, loanField.numberOfUnits)

  specificLoanFieldsValidate(loanField)

  return loanField
}

export const convertValueToLabel = (key: string, value: any) => {
  let label = isEmpty(value) ? '' : String(value)

  const howManyExperiencesOptions: Record<number, string> = {
    ...fromToNumberOptions(5, 24),
    25: '25+',
  }

  if (!isEmpty(value))
    switch (key) {
      case 'productType':
        label = CommercialProdutTypes[value]
        break
      case 'escrowType':
        label = (escrowTypesOptions as any)[value]
        break
      case 'loanTerm':
        label = (loanTermOptions as any)[value]
        break
      case 'howManyExperiences':
        label = howManyExperiencesOptions[value]
        break
      case 'prepaymentPenalty':
        label = prePaymentPenaltyTermInMonthsOptions[value]
        break
      case 'bankruptcy':
        label = bankruptcyOptions[value]
        break
      case 'mortgageLates':
        label = mortgageLatesOptions[value]
        break
      case 'foreclosure':
      case 'deedInLieu':
      case 'shortSale':
        label = foreclosuresOptions[value]
        break
      case 'residency':
        label = citizenshipOptions[value]
        break
      case 'propertyUnderConstruction':
      case 'isPropertyInLeasableState':
      case 'firstTimeHomeBuyer':
      case 'firstTimeHomeInvestor':
      case 'ruralProperty':
      case 'propertyHasBeenListed':
      case 'isShortTermRental':
      case 'decliningMarketProperty':
      case 'isCEMATransaction':
      case 'isNewConstructionProperty':
      case 'isNoScore':
        label = value ? 'Yes' : 'No'
        break
    }

  return label
}

export const getLoanDetailsValue = (key: string, loanFields: Record<string, InputType>) => {
  if (key === 'estimatedCreditScore' && loanFields.isNoScore.value) return 'No Score'
  return convertValueToLabel(key, loanFields[key].value)
}

export const convertLoanDetailStatus = (value: any) => {
  if (value?.from === undefined) return ''
  if (value.from === null && value.to !== null) return `<= ${getPrice3decimal(value.to)}`
  else if (value.from !== null && value.to === null) return `>= ${getPrice3decimal(value.from)}`
  else if (value.from !== null && value.to !== null)
    return `${getPrice3decimal(value.from)} - ${getPrice3decimal(value.to)}`
  else return ''
}
