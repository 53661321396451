import cloneDeep from 'clone-deep'
import {
  type IDscrInterestOnlyLeverageLimit,
  type IDscrLeverageMaxLimits,
  type IProgram,
  bankruptcyOptions,
  citizenshipOptions,
  commercialOccupancyTypeOptions,
  EscrowTypeLabels,
  forbearanceOptions,
  foreclosuresOptions,
  mortgageLatesOptions,
  propertyTypeLabels,
  yesNoOptions,
} from 'config'
import { CrossCollateralBlanketLimit } from 'pages/RateSheetOverview/ExcelSheets/CommercialOnlyProgrammed/LeverageLimits/CrossCollateralBlanketLimit'
import { MaxCashToBorrowerMaxLimits } from 'pages/RateSheetOverview/ExcelSheets/CommercialOnlyProgrammed/LeverageLimits/MaxCashToBorrowerMaxLimits'
import { SecondLienMaxLimits } from 'pages/RateSheetOverview/ExcelSheets/CommercialOnlyProgrammed/LeverageLimits/SecondLienMaxLimits'
import { useEffect, useMemo, useState } from 'react'
import { getDscrLeverageData } from 'services'
import { ButtonGroup } from 'stories/components'

import { DscrFicoLeverageLimit } from './DscrFicoLeverageLimit'
import { DscrFirstTimeInvestorLeverageMaxLimit } from './DscrFirstTimeInvestorLeverageMaxLimit'
import { DscrInterestOnlyLeverageMaxLimit } from './DscrInterestOnlyLeverageMaxLimit'
import { DscrLoanAmountLeverageLimit } from './DscrLoanAmountLeverageLimit'
import { DscrRangeLeverageLimit } from './DscrRangeLeverageLimit'
import { DscrRuralPropertyLeverageMaxLimit } from './DscrRuralPropertyLeverageMaxLimit'
import { DscrValueLeverageLimit } from './DscrValueLeverageLimit'

const initialMenus = {
  occupancy: 'Occupancy',
  propertyType: 'Property Type',
  escrowType: 'Escrow Type',
  bankruptcy: 'Bankruptcy',
  fcSsDil: 'FC/SS/DIL',
  mortgageLates: 'Mortgage Lates',
  forbearance: 'Forbearance / Loan Modification',
  citizenship: 'Citizenship',
  isShortTermRental: 'Is the property a short term rental?',
  decliningMarketProperty: 'Property in a declining market?',
  newConstructionProperty: 'Is the property a new construction?',
  firstTimeHomeBuyer: 'First Time Home Buyer?',
  ruralProperty: 'Rural Property?',
  firstTimeInvestor: 'First Time Investor?',
  interestOnly: 'Interest Only (3)',
  fico: 'FICO',
  dscr: 'DSCR',
  loanAmount: 'Loan Amount',
  maxCashToBorrower: 'Cash in Hand',
  crossCollateral: 'Cross Collateral / Blanket (7)',
  secondLien: 'Second Lien (2)',
}

interface IProps {
  program: IProgram
  loanId: number
  title: string
  isTemplate: boolean
  loading: string
  onSave: (data: any, key: string, type: string) => void
}

export const DscrLeverageLimits = (props: IProps) => {
  const { isTemplate, loanId, program, title, loading, onSave } = props

  const [menus, setMenus] = useState(initialMenus)
  const [selectedMenu, setSelectedMenu] = useState(menus.occupancy)
  const [selectedMenuKey, setSelectedMenuKey] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [leverageData, setLeverageData] = useState<IDscrLeverageMaxLimits>()

  useEffect(() => {
    if (isTemplate) setLeverageData(program.Restriction?.LeverageMaxLimits)
    else {
      ;(async () => {
        setLoading(true)

        try {
          const res = await getDscrLeverageData(loanId, program.ID, 'LeverageMaxLimits')

          setLeverageData(res)
        } catch (err) {
          console.log(err)
        }

        setLoading(false)
      })()
    }
  }, [program])

  useEffect(() => {
    if (!leverageData) {
      setMenus(initialMenus)
      return
    }

    const {
      PropertyTypeLtvMaxLimit,
      EscrowTypeLeverageLimit,
      CitizenshipLtvMaxLimit,
      MortgageLatesLtvMaxLimit,
      FcSsDilLeverageMaxLimit,
      BankruptcyLeverageMaxLimit,
      FirstTimeInvestorLeverageMaxLimit,
      RuralPropertyLeverageMaxLimit,
      MaxCashToBorrowerLeverageMaxLimit,
      ForbearanceLeverageLimit,
      FICOLeverageLimit,
      DscrLeverageLimit,
      LoanAmountLeverageLimit,
      IsShortTermRentalLeverageLimit,
      OccupancyLeverageLimit,
      DecliningMarketPropertyLeverageLimit,
      NewConstructionPropertyLeverageLimit,
    } = leverageData as IDscrLeverageMaxLimits

    const newMenus = cloneDeep(initialMenus)

    Object.keys(initialMenus).forEach((key) => {
      switch (key) {
        case 'newConstructionProperty':
          if (!!NewConstructionPropertyLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${NewConstructionPropertyLeverageLimit.length})`
          }
          break
        case 'decliningMarketProperty':
          if (!!DecliningMarketPropertyLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${DecliningMarketPropertyLeverageLimit.length})`
          }
          break
        case 'occupancy':
          if (!!OccupancyLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${OccupancyLeverageLimit.length})`
          }
          break
        case 'loanAmount':
          if (!!LoanAmountLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${LoanAmountLeverageLimit.length})`
          }
          break
        case 'propertyType':
          if (!!PropertyTypeLtvMaxLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${PropertyTypeLtvMaxLimit.length})`
          }
          break
        case 'isShortTermRental':
          if (!!IsShortTermRentalLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${IsShortTermRentalLeverageLimit.length})`
          }
          break
        case 'escrowType':
          if (!!EscrowTypeLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${EscrowTypeLeverageLimit.length})`
          }
          break
        case 'citizenship':
          if (!!CitizenshipLtvMaxLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${CitizenshipLtvMaxLimit.length})`
          }
          break
        case 'mortgageLates':
          if (!!MortgageLatesLtvMaxLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${MortgageLatesLtvMaxLimit.length})`
          }
          break
        case 'fcSsDil':
          if (!!FcSsDilLeverageMaxLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${FcSsDilLeverageMaxLimit.length})`
          }
          break
        case 'bankruptcy':
          if (!!BankruptcyLeverageMaxLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${BankruptcyLeverageMaxLimit.length})`
          }
          break
        case 'forbearance':
          if (!!ForbearanceLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${ForbearanceLeverageLimit.length})`
          }
          break
        case 'firstTimeInvestor':
          if (!!FirstTimeInvestorLeverageMaxLimit.length && !!FirstTimeInvestorLeverageMaxLimit[0].limits.length) {
            newMenus[key] = initialMenus[key] + ` (${FirstTimeInvestorLeverageMaxLimit[0].limits.length})`
          }
          break
        case 'ruralProperty':
          if (!!RuralPropertyLeverageMaxLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${RuralPropertyLeverageMaxLimit.length})`
          }
          break
        case 'maxCashToBorrower':
          if (!!MaxCashToBorrowerLeverageMaxLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${MaxCashToBorrowerLeverageMaxLimit.length})`
          }
          break
        case 'dscr':
          if (!!DscrLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${DscrLeverageLimit.length})`
          }
          break
        case 'fico':
          if (!!FICOLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${FICOLeverageLimit.length})`
          }
          break
      }
    })

    setMenus(newMenus)

    if (selectedMenuKey) setSelectedMenu((newMenus as any)[selectedMenuKey])
    else setSelectedMenu(newMenus.occupancy)
  }, [leverageData, program])

  const renderContent = useMemo(() => {
    switch (selectedMenu) {
      case menus.loanAmount:
        return (
          <DscrLoanAmountLeverageLimit
            isTemplate={isTemplate}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.LoanAmountLeverageLimit || []}
            onSave={(data) => onSave(data, 'LoanAmountLeverageLimit', 'LeverageMaxLimits')}
          />
        )
      case menus.propertyType:
        return (
          <DscrValueLeverageLimit
            isTemplate={isTemplate}
            title="Property Type"
            options={propertyTypeLabels}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.PropertyTypeLtvMaxLimit || []}
            onSave={(data) => onSave(data, 'PropertyTypeLtvMaxLimit', 'LeverageMaxLimits')}
          />
        )
      case menus.decliningMarketProperty:
        return (
          <DscrValueLeverageLimit
            isTemplate={isTemplate}
            title="Property in a declining market?"
            options={yesNoOptions}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.DecliningMarketPropertyLeverageLimit || []}
            onSave={(data) => onSave(data, 'DecliningMarketPropertyLeverageLimit', 'LeverageMaxLimits')}
          />
        )
      case menus.newConstructionProperty:
        return (
          <DscrValueLeverageLimit
            isTemplate={isTemplate}
            title="Is the property a new construction?"
            options={yesNoOptions}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.NewConstructionPropertyLeverageLimit || []}
            onSave={(data) => onSave(data, 'NewConstructionPropertyLeverageLimit', 'LeverageMaxLimits')}
          />
        )
      case menus.occupancy:
        return (
          <DscrValueLeverageLimit
            isTemplate={isTemplate}
            title="Occupancy"
            options={commercialOccupancyTypeOptions}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.OccupancyLeverageLimit || []}
            onSave={(data) => onSave(data, 'OccupancyLeverageLimit', 'LeverageMaxLimits')}
          />
        )
      case menus.escrowType:
        return (
          <DscrValueLeverageLimit
            isTemplate={isTemplate}
            title="Escrow Type"
            options={EscrowTypeLabels}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.EscrowTypeLeverageLimit || []}
            onSave={(data) => onSave(data, 'EscrowTypeLeverageLimit', 'LeverageMaxLimits')}
          />
        )
      case menus.citizenship:
        return (
          <DscrValueLeverageLimit
            isTemplate={isTemplate}
            title="Citizenship"
            options={citizenshipOptions}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.CitizenshipLtvMaxLimit || []}
            onSave={(data) => onSave(data, 'CitizenshipLtvMaxLimit', 'LeverageMaxLimits')}
          />
        )
      case menus.mortgageLates:
        return (
          <DscrValueLeverageLimit
            isTemplate={isTemplate}
            title="Mortgage Lates"
            options={mortgageLatesOptions}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.MortgageLatesLtvMaxLimit || []}
            onSave={(data) => onSave(data, 'MortgageLatesLtvMaxLimit', 'LeverageMaxLimits')}
          />
        )
      case menus.fcSsDil:
        return (
          <DscrValueLeverageLimit
            isTemplate={isTemplate}
            title="FC/SS/DIL"
            options={foreclosuresOptions}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.FcSsDilLeverageMaxLimit || []}
            onSave={(data) => onSave(data, 'FcSsDilLeverageMaxLimit', 'LeverageMaxLimits')}
          />
        )
      case menus.bankruptcy:
        return (
          <DscrValueLeverageLimit
            isTemplate={isTemplate}
            title="Bankruptcy"
            options={bankruptcyOptions}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.BankruptcyLeverageMaxLimit || []}
            onSave={(data) => onSave(data, 'BankruptcyLeverageMaxLimit', 'LeverageMaxLimits')}
          />
        )
      case menus.forbearance:
        return (
          <DscrValueLeverageLimit
            isTemplate={isTemplate}
            title="Forbearance / Loan Modification"
            options={forbearanceOptions}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.ForbearanceLeverageLimit || []}
            onSave={(data) => onSave(data, 'ForbearanceLeverageLimit', 'LeverageMaxLimits')}
          />
        )
      case menus.firstTimeInvestor:
        return (
          <DscrFirstTimeInvestorLeverageMaxLimit
            isTemplate={isTemplate}
            title={selectedMenu}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.FirstTimeInvestorLeverageMaxLimit || []}
            onSave={(data, key) => onSave(data, key, 'LeverageMaxLimits')}
          />
        )
      case menus.ruralProperty:
        return (
          <DscrRuralPropertyLeverageMaxLimit
            isTemplate={isTemplate}
            title={selectedMenu}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.RuralPropertyLeverageMaxLimit || []}
            onSave={(data) => onSave(data, 'RuralPropertyLeverageMaxLimit', 'LeverageMaxLimits')}
          />
        )
      case menus.interestOnly:
        return (
          <DscrInterestOnlyLeverageMaxLimit
            isTemplate={isTemplate}
            title={selectedMenu}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.InterestOnlyLeverageLimit as IDscrInterestOnlyLeverageLimit}
            onSave={(data, key) => onSave(data, key, 'LeverageMaxLimits')}
          />
        )
      case menus.maxCashToBorrower:
        return (
          <MaxCashToBorrowerMaxLimits
            isTemplate={isTemplate}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.MaxCashToBorrowerLeverageMaxLimit || []}
            onSave={(data) => onSave(data, 'MaxCashToBorrowerLeverageMaxLimit', 'LeverageMaxLimits')}
          />
        )
      case menus.crossCollateral:
        return (
          <CrossCollateralBlanketLimit
            isTemplate={isTemplate}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.CrossCollateralBlanket}
            onSave={(data) => onSave(data, 'CrossCollateralBlanket', 'LeverageMaxLimits')}
          />
        )
      case menus.isShortTermRental:
        return (
          <DscrValueLeverageLimit
            isTemplate={isTemplate}
            title="Is the property a short term rental?"
            loading={loading}
            options={yesNoOptions}
            isLoading={isLoading}
            leverageData={leverageData?.IsShortTermRentalLeverageLimit || []}
            onSave={(data) => onSave(data, 'IsShortTermRentalLeverageLimit', 'LeverageMaxLimits')}
          />
        )
      case menus.firstTimeHomeBuyer:
        return (
          <DscrValueLeverageLimit
            isTemplate={isTemplate}
            title="First Time Home Buyer?"
            loading={loading}
            options={yesNoOptions}
            isLoading={isLoading}
            leverageData={leverageData?.FirstTimeHomeBuyerLeverageLimit || []}
            onSave={(data) => onSave(data, 'FirstTimeHomeBuyerLeverageLimit', 'LeverageMaxLimits')}
          />
        )
      case menus.secondLien:
        return (
          <SecondLienMaxLimits
            isTemplate={isTemplate}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.SecondLienMaxLimit}
            onSave={(data) => onSave(data, 'SecondLienMaxLimit', 'LeverageMaxLimits')}
          />
        )
      case menus.dscr:
        return (
          <DscrRangeLeverageLimit
            isTemplate={isTemplate}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.DscrLeverageLimit || []}
            onSave={(data, key) => onSave(data, key, 'LeverageMaxLimits')}
          />
        )
      case menus.fico:
        return (
          <DscrFicoLeverageLimit
            isTemplate={isTemplate}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.FICOLeverageLimit || []}
            onSave={(data, key) => onSave(data, key, 'LeverageMaxLimits')}
          />
        )
      default:
        return <></>
    }
  }, [selectedMenu, loading, program, isLoading, leverageData])

  const onChangeMenuItem = (value: string) => {
    const selectedKey = Object.keys(menus).find((key) => (menus as any)[key] === value)

    if (selectedKey) setSelectedMenuKey(selectedKey)

    setSelectedMenu(value)
  }

  const renderMenus = useMemo(() => {
    return <ButtonGroup title={Object.values(menus)} value={selectedMenu} onChange={onChangeMenuItem} />
  }, [menus, selectedMenu])

  return (
    <div>
      <div className="text-lg font-variation-settings-600 mb-3">{title}</div>

      {renderMenus}

      <div className="py-2">{renderContent}</div>
    </div>
  )
}
