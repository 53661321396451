import {
  API_STATIC_TABLE,
  API_STATIC_TABLE_INDEX,
  API_STATIC_TABLE_INDEX_ID,
  API_STATIC_TABLE_INDEX_ORDER,
  API_STATIC_TABLE_ORDER,
} from 'config'
import Api from 'services/api'

export const addStaticTable = (loanId: number, programID: number, title: string) => {
  return Api.post(API_STATIC_TABLE, { title }, { loanId, programID })
}

export const changeStaticTableTitle = (loanId: number, programID: number, index: number, title: string) => {
  return Api.put(API_STATIC_TABLE_INDEX, { title }, { loanId, programID, index })
}

export const removeStaticTable = (loanId: number, programID: number, index: number) => {
  return Api.delete(API_STATIC_TABLE_INDEX, {}, { loanId, programID, index })
}

export const addStaticTableRow = (loanId: number, programID: number, index: number, value: string) => {
  return Api.post(API_STATIC_TABLE_INDEX, { value }, { loanId, programID, index })
}

export const updateStaticTableRow = (loanId: number, programID: number, index: number, id: number, value: string) => {
  return Api.put(API_STATIC_TABLE_INDEX_ID, { value }, { loanId, programID, index, id })
}

export const removeStaticTableRow = (loanId: number, programID: number, index: number, id: number) => {
  return Api.delete(API_STATIC_TABLE_INDEX_ID, {}, { loanId, programID, index, id })
}

export const changeStaticTableRowsOrder = (
  loanId: number,
  programID: number,
  index: number,
  orgIndex: number,
  newIndex: number,
) => {
  return Api.put(API_STATIC_TABLE_INDEX_ORDER, {}, { loanId, programID, index, orgIndex, newIndex })
}

export const changeStaticTableOrder = (loanId: number, programID: number, orgIndex: number, newIndex: number) => {
  return Api.put(API_STATIC_TABLE_ORDER, {}, { loanId, programID, orgIndex, newIndex })
}
