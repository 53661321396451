import cloneDeep from 'clone-deep'
import {
  commercialOccupancyTypes,
  IinEligibleProduct,
  ILoan,
  ILockDay,
  ILockDayPrice,
  ILockTermPrice,
  ILtvValues,
  IPriceLoanRlt,
  IPricesData,
  IProduct,
  IProgramInfo,
  IProgramLoanDetails,
  IProgramResponse,
  propertyTypeOptions,
} from 'config'
import { convertNullValue } from 'pages/RateSheetOverview/ExcelSheets/CommercialOnlyProgrammed/logic'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getCompanyRatesheets } from 'services'
import { getLoanDetail, updateLoanDetail } from 'services/apis/loan'
import { priceLoan } from 'services/apis/ratesheet'
import { svgLoading } from 'stories/assets'
import { Button, Select, Toggle } from 'stories/components'
import {
  getItemsFromFullAddress,
  getPrice2decimal,
  InputConvert,
  InputValidate,
  isEmpty,
  removeComma,
  solveDecimalJavascriptSum,
} from 'utils'
import { useTitle } from 'utils/pageTitle'
import { RenderInput } from 'utils/RenderInput'

import {
  arvRelatedFields,
  defaultInputs,
  groupedFields,
  ltvCalcRelatedFields,
  priceLoanRelatedFields,
  visibleChangeFields,
} from './constants'
import {
  calculateLimit,
  calculateLTV,
  estimatedClosingAmountBorrower,
  isLtvAdjustments,
  isNonDSCRProduct,
  loanFieldsValidateAll,
  propertyTypeNumberUnitInputLogic,
  specificLoanFieldsValidate,
  visibleLoansLogic,
} from './logic'
import { LtvValuesAndLimits } from './LtvValuesAndLimits/LtvValuesAndLimits'
import { InEligibleModal } from './modals/InEligibleModal'
import { PriceCalculationModal } from './modals/PriceCalculationModal'

interface ISelectedCompany {
  company: string
  loanId: number
}

export function PricingPlayground() {
  useTitle('Pricing - RTLTrader')

  const navigate = useNavigate()
  const params = useParams()

  const isNewLoan = params.id === 'new'

  const [visibleFields, setVisibleFields] = useState(['loanName', 'productType', 'transactionType'])
  const [loanFields, setLoanFields] = useState(defaultInputs())
  const [fieldValueChanged, setFieldValueChanged] = useState(false)
  const [edit, setEdit] = useState(false)
  const [showingFields, setShowingFields] = useState(groupedFields)
  const [companies, setCompanies] = useState<string[]>([])
  const [loanIDs, setLoanIDs] = useState<string[]>([])
  const [ratesheets, setRatesheets] = useState<Record<string, string[]>>()
  const [selectedCompany, setSelectedCompany] = useState<ISelectedCompany>({
    company: '',
    loanId: NaN,
  })
  const [loading, setLoading] = useState('')
  const [ltvValues, setLtvValues] = useState<ILtvValues>()
  const [ltvLimits, setLtvLimits] = useState<ILtvValues>()
  const [programs, setPrograms] = useState<IProgramInfo[]>([])
  const [products, setProducts] = useState<IProduct[]>([])
  const [inEligibleProducts, setInEligibleProducts] = useState<IinEligibleProduct>()
  const [programsLoanDetails, setProgramsLoanDetails] = useState<IProgramLoanDetails>({})
  const [lockDays, setLockDays] = useState<ILockDay[]>([])
  const [prices, setPrices] = useState<IPricesData[]>([])
  const [selectedProgram, setSelectedProgram] = useState<ILockDayPrice[]>([])
  const [selectedProductId, setSelectedProductId] = useState<number>()
  const [selectedProgramId, setSelectedProgramId] = useState<number>()
  const [lockTermPrice, setLockTermPrice] = useState<ILockTermPrice>()
  const [selectedRate, setSelectedRate] = useState<number>()
  const [program, setProgram] = useState<IProgramResponse>()
  const [modal, setModal] = useState<string>('')
  const [showSubPrograms, setShowSubPrograms] = useState(false)
  const [priceDisplay, setPriceDisplay] = useState('Price')

  const isDSCR = useMemo(() => {
    return !isNonDSCRProduct(loanFields.productType.value)
  }, [loanFields])

  const isPoints = useMemo(() => {
    return priceDisplay === 'Points'
  }, [priceDisplay])

  useEffect(() => {
    ;(async () => {
      setLoading('fetchRatesheet')
      let newInputs = cloneDeep(loanFields)

      const nRatesheets: Record<string, string[]> = await getCompanyRatesheets()

      if (Object.keys(nRatesheets).length > 0) {
        const companiesList = Object.keys(nRatesheets).map((key) => key)

        setRatesheets(nRatesheets)
        setCompanies(companiesList)
      }

      if (!isNewLoan) {
        try {
          const loan: ILoan = await getLoanDetail(Number(params.id))

          Object.keys(newInputs).forEach((key) => {
            newInputs[key].value = (loan as any)[key]
          })

          const companyName = Object.keys(nRatesheets).find((key) =>
            nRatesheets[key].includes(loan.ratesheet.loanId.toString()),
          )

          if (companyName) {
            setSelectedCompany({
              company: companyName,
              loanId: loan.ratesheet.loanId,
            })

            const loanIDsList = nRatesheets[companyName]

            loanIDsList.sort((x, y) => Number(y) - Number(x))

            setLoanIDs(loanIDsList)
          } else {
            toast(`Not Found Rate Sheet`, { type: 'error' })
            setLoading('')
            return
          }
        } catch (error) {
          console.log(error)
        }
      }

      setLoanFields(loanFieldsValidateAll(cloneDeep(newInputs)))
      setVisibleFields(visibleLoansLogic(newInputs))
      setLoading('')
    })()
  }, [])

  useEffect(() => {
    const targetProgram = programs.find((item) => item.ID === selectedProgramId)
    if (targetProgram) setPriceDisplay(targetProgram.PriceOption)
  }, [selectedProgramId])

  useEffect(() => {
    const flag: { [key: string]: boolean } = {}
    visibleFields.map((key) => (flag[key] = true))

    const nShowedFields: string[][] = []

    groupedFields.map((fields) => {
      const newFields = fields.filter((key) => flag[key] === true)
      if (newFields.length > 0) {
        nShowedFields.push(newFields)
      }
    })

    setShowingFields(nShowedFields)
  }, [visibleFields])

  useEffect(() => {
    if (isEmpty(loading) && visibleFields.length > 3) onPriceLoan(true)
  }, [showSubPrograms])

  const onGetPriceLimit = () => {
    if (!isDSCR) {
      const res = calculateLimit(loanFields)
      setLtvValues({
        aivLtv: removeComma(res.LTV),
        arvLtv: removeComma(res.ARV_LTV),
        ltc: removeComma(res.LTC),
        ltp: removeComma(res.LTP),
      })
    } else setLtvValues(undefined)
  }

  const onChangeLoanFields = async (key: string, e: any) => {
    let value = InputConvert(loanFields[key], e)

    let tempLoanFields = cloneDeep(loanFields)
    tempLoanFields[key].value = value
    tempLoanFields[key].error = InputValidate({ ...tempLoanFields[key] })

    if (arvRelatedFields.includes(key)) tempLoanFields = specificLoanFieldsValidate(cloneDeep(tempLoanFields))

    if (key === 'propertyType')
      tempLoanFields.numberOfUnits = propertyTypeNumberUnitInputLogic(
        tempLoanFields.propertyType.value,
        tempLoanFields.numberOfUnits,
      )

    if (visibleChangeFields.includes(key)) {
      setVisibleFields(visibleLoansLogic(tempLoanFields))
    }

    if (['productType', 'transactionType'].includes(key)) {
      const productType = tempLoanFields.productType.value
      if (isNonDSCRProduct(productType)) {
        tempLoanFields.amortizationType.value = 'IO - Fixed'
        tempLoanFields.amortizationType.error = ''
      }
    }

    if (['isNoScore'].includes(key) && value) tempLoanFields.estimatedCreditScore.error = ''

    setLoanFields(tempLoanFields)
    setFieldValueChanged(true)
    setEdit(true)
  }

  const onBlur = async (key: string) => {
    if (!fieldValueChanged) return
    setFieldValueChanged(false)

    let continueSaveChange = true
    if (loanFields[key].error?.length) continueSaveChange = false
    if (!isEmpty(loanFields[key].value)) continueSaveChange = true

    if (!continueSaveChange) {
      if (priceLoanRelatedFields.includes(key)) {
        setPrices([])
        setSelectedProgram([])
        setInEligibleProducts({})
      }
      return
    }

    try {
      let promises = []

      if (ltvCalcRelatedFields.includes(key)) promises.push(onGetPriceLimit())

      if (priceLoanRelatedFields.includes(key)) promises.push(onPriceLoan(true))

      await Promise.all(promises)
    } catch (error) {
      console.log(error)
    }
  }

  const onSaveLoanDetail = async () => {
    if (!selectedCompany.loanId) {
      toast('No ratesheet!', { type: 'error' })
      return
    }
    if (!!loanFields.loanName.error) return

    setLoading('saveLoan')

    try {
      let data: Record<string, any> = {}

      Object.keys(loanFields).forEach((key) => {
        data[key] = loanFields[key].value
      })

      data.ratesheetID = Number(selectedCompany.loanId)

      const loanDetailId = await updateLoanDetail(data, params.id)

      toast(`Successfully ${isNewLoan ? 'updated' : 'created'}`, { type: 'info' })

      navigate(`/pricing/${loanDetailId}`)

      setModal('')
    } catch (error) {
      console.log(error)
    }
    setLoading('')
    setEdit(false)
  }

  const checkValidation = () => {
    let flag = true
    visibleFields.map((key) => {
      if (priceLoanRelatedFields.includes(key)) {
        if (!!loanFields[key]?.error?.length) flag = false
      }
    })
    let { closingError, rehabFinancedPercent, totalRehabFinancedPercent } = estimatedClosingAmountBorrower(loanFields)
    if (closingError.length) flag = false
    if (removeComma(rehabFinancedPercent) > 100) flag = false
    if (removeComma(rehabFinancedPercent) > 0 && removeComma(rehabFinancedPercent) < 80) flag = false
    if (removeComma(totalRehabFinancedPercent) > 120) flag = false
    if (removeComma(totalRehabFinancedPercent) > 0 && removeComma(totalRehabFinancedPercent) < 100) flag = false
    return flag
  }

  const onPriceLoan = async (isSync: boolean = false) => {
    const isAvailable = checkValidation()

    if (!isAvailable) {
      if (!isSync) toast('Please input all Required and Valid information!', { type: 'error' })

      setPrices([])
      setSelectedProgram([])
      return
    }

    if (isEmpty(selectedCompany.company) || isNaN(selectedCompany.loanId)) {
      toast('Select a rate sheet!', { type: 'error' })

      setPrices([])
      setSelectedProgram([])
      return
    }
    const reqBody: { [key: string]: any } = {}
    visibleFields.map((field) => {
      reqBody[field] = loanFields[field].value
    })

    setSelectedProgram([])
    setPrices([])

    const propertyAddress: any = await getItemsFromFullAddress(reqBody.subjectPropertyAddress)

    if (reqBody.propertyState !== propertyAddress.state) {
      toast('Property State does not match with Subject Property Address', { type: 'error' })
      return
    }

    const limitValues = calculateLimit(loanFields)

    const data = {
      loan_id: Number(selectedCompany.loanId),
      data: {
        ProductType: reqBody.productType,
        TransactionType: reqBody.transactionType,
        PropertyUnderConstruction: reqBody.propertyUnderConstruction,
        IsPropertyInLeasableState: reqBody.isPropertyInLeasableState,
        IsShortTermRental: reqBody.isShortTermRental,
        IsNewConstructionProperty: reqBody.isNewConstructionProperty,
        EstimatedClosingAmount: estimatedClosingAmountBorrower(loanFields).value,
        PropertyAddress: propertyAddress,
        LoanTerm: reqBody.loanTerm,
        Experience: reqBody.experience,
        ExperienceCount:
          reqBody.experience === '5+ Transactions'
            ? removeComma(reqBody.howManyExperiences)
            : removeComma(reqBody.experience?.charAt(0)),
        Citizenship: reqBody.residency,
        IncomeDocumentation: 'DebtServiceCoverageRatio',
        AmortizationType: reqBody.amortizationType,
        CreditEvent: {
          MortgageLates: reqBody.mortgageLates,
          Bankruptcy: reqBody.bankruptcy,
          Foreclosure: reqBody.foreclosure,
          ShortSale: reqBody.shortSale,
          DeedInLieu: reqBody.deedInLieu,
        },
        ForbearanceLoanModification: reqBody.forbearanceLoanModification,
        EscrowType: reqBody.escrowType,
        Fico: reqBody.isNoScore ? 0 : reqBody.estimatedCreditScore,
        IsFirstTimeHomebuyer: reqBody.firstTimeHomeBuyer,
        IsFirstTimeInvestor: reqBody.firstTimeHomeInvestor,
        IsRuralProperty: reqBody.ruralProperty,
        IsDecliningMarketProperty: reqBody.decliningMarketProperty,
        LoanAmount: reqBody.proposedLoanAmount,
        AsIsValue: reqBody.asIsValue,
        PurchasePrice: reqBody.propertyPurchasePrice,
        RehabBudget: reqBody.rehabBudget,
        AfterRepairValue: reqBody.afterRepairValue,
        MonthsReserved: reqBody.monthsReserve,
        NumberOfUnits: reqBody.numberOfUnits,
        Occupancy: commercialOccupancyTypes[reqBody.proposedOccupancy],
        PrePaymentPenaltyTermsInMonths: reqBody.prepaymentPenalty,
        PropertyHasBeenListed: reqBody.propertyHasBeenListed,
        PropertyType: reqBody.propertyType === 'Condo' ? reqBody.condoType : propertyTypeOptions[reqBody.propertyType],
        LTV: limitValues.LTV,
        CLTV: limitValues.CLTV,
        AIV_LTV: limitValues.LTV,
        ARV_LTV: limitValues.ARV_LTV,
        LTC: limitValues.LTC,
        LTP: limitValues.LTP,
        MaxLtvAdjustment: removeComma(reqBody.ltvAdjustment),
        MaxCLtvAdjustment: removeComma(reqBody.cltvAdjustment),
        MaxAivLtvAdjustment: removeComma(reqBody.aivLtvAdjustment),
        MaxArvLtvAdjustment: removeComma(reqBody.arvLtvAdjustment),
        MaxLtcAdjustment: removeComma(reqBody.ltcAdjustment),
        MaxLtpAdjustment: removeComma(reqBody.ltpAdjustment),
        State: reqBody.propertyState,
        isCEMATransaction: reqBody.isCEMATransaction,
        SecondLien: removeComma(reqBody.secondLien),
        SecondLienPayment: removeComma(reqBody.secondLienPayment),
        BrokerAdjustment: {
          rate: 0,
          price: 0,
          rateRange: { from: 0, to: 0 },
          priceRange: { from: 0, to: 0 },
        },
        NumberOfProperties: 1 + (reqBody.additionalPropertyAddress?.length || 0),
        TaxData: {
          ProposedMonthlyInsurance: removeComma(reqBody.proposedMonthlyInsurance),
          ProposedMonthlyTaxes: removeComma(reqBody.proposedMonthlyTaxes),
          ProposedMonthlyHoaDues: removeComma(reqBody.proposedMonthlyHoaDues),
          ProposedMonthlyRent: removeComma(reqBody.proposedMonthlyRent),
          FloodInsurance: removeComma(reqBody.floodInsurance),
          SchoolTax: removeComma(reqBody.schoolTax),
          OtherTax: removeComma(reqBody.otherTax),
        },
        RateAdjustment: 0,
        ShowSubPrograms: showSubPrograms,
        EachCompanyParams: {
          type: 'Commercial',
          PropertyType: reqBody.propertyType,
          CommercialOccupancy: reqBody.proposedOccupancy,
          CommercialOnlyProgrammed: !isDSCR,
          CommercialOnlyProgramLoanAmountValidationOverride: false,
        },
      },
    }

    try {
      setSelectedProgram([])
      setPrices([])

      setLoading('priceLoan')

      const res: IPriceLoanRlt = await priceLoan(data)

      setPrograms(res.Programs)
      setProducts(res.MortgageProducts)
      setInEligibleProducts(res.InEligibleProducts)
      setProgramsLoanDetails(res.ProgramLoanDetails)
      setLockDays(res.LockDays)
      setPrices(res.Prices)

      if (!isEmpty(res.Prices)) {
        let productId = Infinity
        let programId = Infinity

        for (let i = 0; i < res.Prices.length; i++) {
          if (res.Prices[i].Programs.length > 0) {
            productId = res.Prices[i].ProductID
            programId = res.Prices[i].Programs[0].ProgramID
            break
          }
        }

        if (productId !== Infinity && programId !== Infinity) {
          setSelectedProductId(productId)
          setSelectedProgramId(programId)

          const priceData = res.Prices.find((item) => item.ProductID === productId)?.Programs.find(
            (item) => item.ProgramID === programId,
          )?.Prices

          if (priceData) setSelectedProgram(priceData)
          else setSelectedProgram([])

          const targetProgram = res.Programs.find((item) => item.ID === programId)

          if (targetProgram?.LtvLimit) {
            const limitData: ILtvValues = {
              aivLtv: targetProgram.LtvLimit.max_aiv_ltv,
              arvLtv: targetProgram.LtvLimit.max_arv_ltv,
              ltc: targetProgram.LtvLimit.max_ltc,
              ltp: targetProgram.LtvLimit.max_ltp,
            }
            setLtvLimits(limitData)
          }
          onGetPriceLimit()
        }
      }
    } catch (error) {
      console.log(error)
    }

    setLoading('')
  }

  const onSelectCompany = (value: string) => {
    if (!ratesheets) return

    const loanIdsList = ratesheets[value]

    if (loanIdsList) loanIdsList.sort((x, y) => Number(y) - Number(x))

    setLoanIDs(loanIdsList)
    setSelectedCompany({ company: value, loanId: NaN })
    setEdit(true)
  }

  const onSelectProgram = (programId: number, productId: number) => {
    const priceData = prices
      .find((item) => item.ProductID === productId)
      ?.Programs.find((item) => item.ProgramID === programId)?.Prices

    if (priceData) setSelectedProgram(priceData)
    else setSelectedProgram([])

    const targetProgram = programs.find((item) => item.ID === programId)

    if (targetProgram?.LtvLimit) {
      const limitData: ILtvValues = {
        aivLtv: targetProgram.LtvLimit.max_aiv_ltv,
        arvLtv: targetProgram.LtvLimit.max_arv_ltv,
        ltc: targetProgram.LtvLimit.max_ltc,
        ltp: targetProgram.LtvLimit.max_ltp,
      }
      setLtvLimits(limitData)
    }

    setSelectedProductId(productId)
    setSelectedProgramId(programId)
  }

  const onShowCalculation = async (price: ILockTermPrice, rate: number) => {
    const programData = prices
      .find((item) => item.ProductID === selectedProductId)
      ?.Programs.find((item) => item.ProgramID === selectedProgramId)

    setProgram(programData)
    setLockTermPrice(price)
    setSelectedRate(rate)
    setModal('calculation')
  }

  const renderPrograms = useMemo(() => {
    if (isEmpty(products) || isEmpty(programs)) return

    let elements = new Array(programs.length)

    for (let i = 0; i < elements.length; i++) {
      elements[i] = new Array(products.length).fill({
        exist: false,
        programID: 0,
        productID: 0,
        programName: '',
      })
    }

    let programsMapping: Record<string, number> = {}
    let productsMapping: Record<string, number> = {}

    for (let i = 0; i < programs.length; i++) {
      programsMapping[programs[i].ID] = i
    }

    for (let i = 0; i < products.length; i++) {
      productsMapping[products[i].Id] = i
    }

    for (let i = 0; i < prices.length; i++) {
      for (let j = 0; j < prices[i].Programs.length; j++) {
        try {
          if (!!prices[i].Programs[j].Prices.length)
            elements[programsMapping[prices[i].Programs[j].ProgramID]][productsMapping[prices[i].ProductID]] = {
              exist: true,
              programID: prices[i].Programs[j].ProgramID,
              productID: prices[i].ProductID,
              programName: prices[i].Programs[j].ProgramName,
            }
        } catch (error) {
          console.log('track element error', error)
        }
      }
    }

    let emptyFlag = true
    const programJSXElements = []

    for (let i = 0; i < programs.length; i++) {
      if (elements[i].find((item: Record<string, any>) => item.exist)) {
        emptyFlag = false

        programJSXElements.push(
          <tr key={i}>
            {elements[i].map((item1: Record<string, any>, index: number) => {
              return (
                <td
                  colSpan={products.length === 1 ? 2 : 1}
                  key={`${i}-${index}`}
                  onClick={() => {
                    if (item1.exist) {
                      onSelectProgram(item1.programID, item1.productID)
                    }
                  }}
                  className={`${
                    selectedProductId == item1.productID && selectedProgramId == item1.programID
                      ? 'bg-sky-500 text-white'
                      : ''
                  } border px-1 py-2 text-center hover:cursor-pointer`}
                >
                  {item1.programName}
                </td>
              )
            })}
          </tr>,
        )
      }
    }

    if (!emptyFlag) {
      return programJSXElements
    } else {
      return (
        <tr>
          <td
            colSpan={products.length === 1 ? 2 : products.length}
            className="border p-2 hover:cursor-pointer hover:bg-sky-500 hover:text-white"
          >
            The entered criteria does not meet product guidelines...
          </td>
        </tr>
      )
    }
  }, [prices, selectedProductId, selectedProgramId])

  const renderLoan = useMemo(() => {
    return (
      <div>
        {showingFields.map((fields, index) => {
          const isLtvAdjustment = isLtvAdjustments(fields)
          const showLtvAdjustments = isLtvAdjustment && isNewLoan
          const hideLtvAdjustments = isLtvAdjustment && !isNewLoan

          if (hideLtvAdjustments) return <></>

          return (
            <div
              key={index}
              className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 mb-5 rounded-t border border-solid border-gray-200 shadow p-2 justify-around"
            >
              {showLtvAdjustments && (
                <div className=" col-span-2 text-[16px] font-variation-settings-600">Max LTV Adjustments</div>
              )}
              {fields.map((key, index1) => {
                let rlt = []
                let cn = loanFields[key]?.length === 1 ? 'md:col-span-2' : 'md:col-span-1'

                if (key === 'estimatedDscr') {
                } else
                  rlt.push(
                    <div key={`${index}-${index1}`} className={cn}>
                      <RenderInput
                        input={{
                          ...loanFields[key],
                          error: loanFields[key].error,
                          disabled: key === 'estimatedCreditScore' && loanFields.isNoScore.value,
                        }}
                        Key={key}
                        onChange={onChangeLoanFields}
                        onBlur={onBlur}
                      />
                    </div>,
                  )
                if (key === 'constructionReserve') {
                  const { rehabFinancedPercent, totalRehabFinancedPercent, totalRehabFinancedTooltip } =
                    estimatedClosingAmountBorrower(loanFields)

                  const constructionFinancedHoldbackError =
                    removeComma(rehabFinancedPercent) > 100 || removeComma(rehabFinancedPercent) < 80
                      ? `Must be between 80% and 100%`
                      : ''
                  const totalRehabFinancedPercentError =
                    removeComma(totalRehabFinancedPercent) > 120 || removeComma(totalRehabFinancedPercent) < 100
                      ? `Must be between 100% and 120%`
                      : ''

                  rlt.push(
                    <div key={`rehabFinancedPercent-${index}-${index1}`}>
                      <RenderInput
                        input={{
                          title: 'Construction Financed Holdback %',
                          inputType: 'text',
                          prefix: '%',
                          value: rehabFinancedPercent,
                          readOnly: true,
                          error: constructionFinancedHoldbackError,
                          tooltip: `'Construction Amount Financed'/<br/>'Construction Budget'`,
                        }}
                        Key={'rehabFinancedPercent'}
                        onChange={onChangeLoanFields}
                      />
                    </div>,
                  )
                  rlt.push(
                    <div key={`totalRehabFinancedPercent-${index}-${index1}`}>
                      <RenderInput
                        input={{
                          title: 'Total Construction Financed %',
                          inputType: 'text',
                          prefix: '%',
                          value: totalRehabFinancedPercent,
                          readOnly: true,
                          tooltip: totalRehabFinancedTooltip,
                          error: totalRehabFinancedPercentError,
                        }}
                        Key={'totalRehabFinancedPercent'}
                        onChange={onChangeLoanFields}
                      />
                    </div>,
                  )
                }

                if (key === 'proposedLoanAmount') {
                  rlt.push(
                    <div key={`ltv-${index}-${index1}`} className="md:col-span-1">
                      <RenderInput
                        input={{
                          title: 'LTV',
                          inputType: 'text',
                          prefix: '%',
                          value: calculateLTV(loanFields).LTV,
                          readOnly: true,
                        }}
                        Key={'LTV'}
                        onChange={onChangeLoanFields}
                      />
                    </div>,
                  )

                  rlt.push(
                    <div key={`cltv-${index}-${index1}`} className="md:col-span-1">
                      <RenderInput
                        input={{
                          title: 'CLTV',
                          inputType: 'text',
                          prefix: '%',
                          value: calculateLTV(loanFields).CLTV,
                          readOnly: true,
                        }}
                        Key={'CLTV'}
                        onChange={onChangeLoanFields}
                      />
                    </div>,
                  )
                }

                if (key === 'closingCostEstimate') {
                  let { value, closingTooltip, closingError } = estimatedClosingAmountBorrower(loanFields)

                  rlt.push(
                    <div key={`atClosingBorrowerAmount-${index}-${index1}`} className={cn}>
                      <RenderInput
                        input={{
                          title: `Estimated Closing Amount ${
                            loanFields.transactionType.value === 'Refinance' ? 'to' : 'from'
                          } Borrower`,
                          inputType: 'text',
                          prefix: '$',
                          value: getPrice2decimal(value),
                          readOnly: true,
                          tooltip: closingTooltip,
                          error: closingError,
                        }}
                        Key={'atClosingBorrowerAmount'}
                        onChange={onChangeLoanFields}
                      />
                    </div>,
                  )
                }
                return rlt
              })}
            </div>
          )
        })}
      </div>
    )
  }, [loanFields, showingFields])

  return (
    <div className="py-6 px-2">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 max-w-screen-2xl m-auto">
        <div className="rounded shadow1 p-3 md:p-7 pb-3 md:pb-3 bg-white w-full h-fit">
          <h1 className="text-2xl font-variation-settings-600 flex items-center flex-wrap mb-3">
            {`Loan Details${loanFields.loanName.value ? ' - ' + loanFields.loanName.value : ''}`
              .split(' ')
              .map((word, index) => (
                <span key={index} className="mr-2">
                  {word}
                </span>
              ))}
          </h1>

          <div className="flex items-center flex-wrap mb-3">
            <span className="mr-2 mb-4 font-variation-settings-600">RateSheets:</span>
            {loading === 'fetchRatesheet' ? (
              <span className="flex justify-center my-4">
                <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
              </span>
            ) : (
              <div className="flex items-center">
                <div className="mr-2">
                  <Select
                    id="company"
                    value={selectedCompany.company}
                    options={companies}
                    hasDefaultOption={true}
                    className="w-[250px]"
                    onChange={(value) => onSelectCompany(value)}
                  />
                </div>
                <Select
                  id="loan-id"
                  value={selectedCompany.loanId.toString()}
                  options={loanIDs}
                  hasDefaultOption={true}
                  className="w-[90px] mr-2"
                  onChange={(value) => {
                    setSelectedCompany({ ...selectedCompany, loanId: value })
                    setEdit(true)
                  }}
                />

                {edit && (
                  <div className="mb-2">
                    <Button
                      className="mr-2 text-[16px] pt-1 pb-1"
                      loading={loading === 'saveLoan'}
                      onClick={onSaveLoanDetail}
                    >
                      Save
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>

          {renderLoan}
        </div>

        <div className="rounded shadow1 p-3 md:p-7 pb-3 md:pb-3 bg-white w-full h-fit">
          <h1 className="text-2xl font-variation-settings-600 flex items-center mb-3">Request Rate Sheet</h1>
          <p className="mb-2 text-sm text-gray-500">Introductions:</p>
          <p className="text-sm text-gray-500">
            1. Click on the “Price Loan” button to get your interest rates and base prices.
          </p>
          <p className="text-sm text-gray-500">2. You need to complete loan details to price your loan.</p>

          <Button
            loading={loading === 'priceLoan'}
            disabled={loading === 'fetchRatesheet'}
            className="w-full mt-4"
            onClick={() => onPriceLoan()}
          >
            Price Loan
          </Button>

          {<LtvValuesAndLimits values={ltvValues} limits={ltvLimits} />}

          {!isEmpty(prices) && (
            <div className="mt-4 overflow-x-auto">
              <table className="table w-full text-center">
                <thead className="bg-gray-100">
                  <tr>
                    {products.map((item, index) => (
                      <th
                        key={index}
                        colSpan={products.length === 1 ? 2 : 1}
                        className="border py-2 px-1 min-w-[100px]"
                      >
                        {item.Description}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="text-sm">{renderPrograms}</tbody>
              </table>
            </div>
          )}

          <div className="flex mt-5 items-center justify-between">
            {!!selectedProgram.length && (
              <Select
                id="priceDisplay"
                title="Price Display"
                className="w-24 -mb-4"
                options={['Price', 'Points']}
                value={priceDisplay}
                onChange={(value: any) => setPriceDisplay(value)}
              />
            )}

            {isDSCR && !!selectedProgram.length && (
              <Toggle
                id="showSubPrograms"
                title="Show Sub Programs"
                value={showSubPrograms}
                onChange={(checked) => {
                  setShowSubPrograms(checked)
                }}
              />
            )}
          </div>

          {!isEmpty(selectedProgram) && (
            <table className="table w-full text-center my-4">
              <thead className="bg-gray-100 text-sm font-semibold">
                <tr>
                  <th className="border p-2">Rate</th>
                  {lockDays.map((item, index) => (
                    <th className="border p-2" key={index}>
                      {item.description} Days Lock
                    </th>
                  ))}

                  {!isDSCR && (
                    <>
                      <td className="border p-2">Rate Spread</td>
                      <td className="border p-2">Exit Fee</td>
                    </>
                  )}

                  {isDSCR && <td className="border p-2">DSCR</td>}
                </tr>
              </thead>
              <tbody className="text-sm">
                {selectedProgram?.map((item, index: number) => (
                  <tr key={index}>
                    <td className="border p-2">{item.Rate}</td>
                    {item.LockTermPrices.map((price, id: number) => (
                      <td
                        key={index + id}
                        className="border p-2 hover:cursor-pointer hover:bg-sky-500 hover:text-white"
                        onClick={() => onShowCalculation(price, item.Rate)}
                      >
                        {isPoints ? solveDecimalJavascriptSum([100, -1 * removeComma(price.Price)]) : price.Price}
                      </td>
                    ))}
                    {!isDSCR && (
                      <>
                        <td className="border p-2">{convertNullValue(item.RateSpread)}</td>
                        <td className="border p-2">{convertNullValue(item.ExitFee)}</td>
                      </>
                    )}
                    {isDSCR && <td className="border p-2">{item.Dscr}</td>}
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {!isEmpty(prices) && !isEmpty(inEligibleProducts) && (
            <div className="flex justify-center mt-3">
              <Button color="red" size="sm" onClick={() => setModal('showInEligible')}>
                View Ineligible Programs
              </Button>
            </div>
          )}
        </div>
      </div>

      {modal === 'calculation' && program && selectedRate && lockTermPrice && (
        <PriceCalculationModal
          isOpen={modal === 'calculation'}
          program={program}
          rate={selectedRate}
          price={lockTermPrice}
          onClose={() => setModal('')}
        />
      )}
      {modal === 'showInEligible' && inEligibleProducts && (
        <InEligibleModal
          isOpen={modal === 'showInEligible'}
          loanFields={loanFields}
          programs={programs}
          visibleFields={visibleFields}
          inEligibleProducts={inEligibleProducts}
          programsLoanDetails={programsLoanDetails}
          onClose={() => setModal('')}
        />
      )}
    </div>
  )
}
