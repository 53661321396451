import { ArchiveBoxXMarkIcon, TrashIcon } from '@heroicons/react/24/outline'
import { PlainInput } from 'components/PlainInput'
import type { IDscrValueLeverageLimit } from 'config'
import {
  convertNullToBlank,
  convertNullToBlankValueToDecimal,
} from 'pages/RateSheetOverview/ExcelSheets/CommercialOnlyProgrammed/logic'
import { Fragment } from 'react'
import { Select } from 'stories/components'
import { getPrice3decimal } from 'utils'

interface IProps {
  data: IDscrValueLeverageLimit[]
  title: string
  isTemplate: boolean
  readOnlyValue?: boolean
  options?: Record<string, string>
  addSubLimit: (index: number) => void
  onChangeFICO: (index: number, idx: number, key: string, value: any) => void
  onChangeValue?: (index: number, value: string) => void
  onChangeLoanAmountOverly: (
    index: number,
    idx: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: string,
    value: any,
  ) => void
  onChangeLtvLimit: (
    index: number,
    idx: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: 'min' | 'max',
    value: any,
  ) => void
  onChangeCLTVLimit: (index: number, idx: number, loanPurpose: 'purchase' | 'nocashout' | 'cashout', value: any) => void
  onChangeMinDscrMonthsReserve: (
    index: number,
    idx: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: string,
    value: any,
  ) => void
  removeLtvLimit?: (index: number, idx: number) => void
  onChangeMaxLimits: (index: number, key: 'LTV' | 'CLTV' | 'loanAmount', value: any) => void
  onChangeMinFICO: (index: number, value: any) => void
  onChangeMinMaxLoanAmount: (index: number, key: 'min' | 'max', value: any) => void
}

export const DscrLeverageLimitTable = (props: IProps) => {
  const {
    data,
    title,
    isTemplate,
    readOnlyValue = false,
    options = {},
    addSubLimit,
    onChangeFICO,
    onChangeValue = () => {},
    onChangeLoanAmountOverly,
    onChangeLtvLimit,
    onChangeCLTVLimit,
    onChangeMinDscrMonthsReserve,
    removeLtvLimit = () => {},
    onChangeMaxLimits,
    onChangeMinFICO,
    onChangeMinMaxLoanAmount,
  } = props

  const minDscrMonthsReserveKeys = ['minDSCR', 'minMonthsReserve']

  if (data.length === 0)
    return (
      <div className="w-full flex justify-center items-center mt-10">
        <div className="flex flex-col items-center gap-1">
          <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
          <span className="text-gray-400">No Limits</span>
        </div>
      </div>
    )

  return (
    <div className="overflow-x-auto">
      <table className="table min-w-max w-full text-center text-sm">
        <thead className="bg-gray-100">
          <tr>
            <th rowSpan={2} className="border p-1 whitespace-nowrap">
              {title}
            </th>
            <th colSpan={2} className="border p-1 whitespace-nowrap">
              Max Limits
            </th>
            <th colSpan={2} className="border p-1 whitespace-nowrap">
              Loan Amount
            </th>
            <th rowSpan={2} className="border p-1 whitespace-nowrap w-[80px]">
              Min FICO
            </th>
            <th colSpan={2} className="border p-1">
              FICO
            </th>
            <th rowSpan={2} className="border p-1">
              Loan Purpose
            </th>
            <th colSpan={2} className="border p-1">
              Loan Amount
            </th>
            <th rowSpan={2} className="border p-1 w-[80px]">
              Min DSCR
            </th>
            <th rowSpan={2} className="border p-1 w-[100px]">
              Min Months Reserve
            </th>
            <th colSpan={2} className="border p-1">
              LTV
            </th>
            <th rowSpan={2} className="border p-1 w-[85px]">
              Max CLTV
            </th>
            {isTemplate && !readOnlyValue && (
              <th rowSpan={2} className="border p-1">
                Action
              </th>
            )}
          </tr>
          <tr>
            <th className="border p-1 w-[60px]">LTV</th>
            <th className="border p-1 w-[60px]">CLTV</th>
            <th className="border p-1 w-[100px]">Min</th>
            <th className="border p-1 w-[100px]">Max</th>
            <th className="border p-1 w-[60px]">From</th>
            <th className="border p-1 w-[60px]">To</th>
            <th className="border p-1 w-[100px]">From</th>
            <th className="border p-1 w-[100px]">To</th>
            <th className="border p-1 w-[60px]">Min</th>
            <th className="border p-1 w-[60px]">Max</th>
          </tr>
        </thead>

        <tbody>
          {data.map((item, index) => {
            return (
              <Fragment key={index}>
                <tr>
                  <td rowSpan={3 * item.limits.length + 1} className={`border whitespace-nowrap p-1`}>
                    {isTemplate && !readOnlyValue ? (
                      <Select
                        id="value"
                        options={options}
                        hasDefaultOption={true}
                        value={item.value}
                        className="w-full mb-[-16px]"
                        onChange={(v) => onChangeValue(index, v)}
                      />
                    ) : (
                      options[item.value] || item.value
                    )}

                    {isTemplate && (
                      <div className="mt-5 flex justify-center w-full">
                        <span
                          className="cursor-pointer text-shade-blue hover:scale-105 transform duration-100 hover:underline"
                          onClick={() => addSubLimit(index)}
                        >
                          Add Limit
                        </span>
                      </div>
                    )}
                  </td>
                  <td rowSpan={3 * item.limits.length + 1} className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(item.LTV)}
                        origin={convertNullToBlank(item.LTV)}
                        content={convertNullToBlank(item.LTV)}
                        onChange={(value: any) => onChangeMaxLimits(index, 'LTV', value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                  <td rowSpan={3 * item.limits.length + 1} className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(item.CLTV)}
                        origin={convertNullToBlank(item.CLTV)}
                        content={convertNullToBlank(item.CLTV)}
                        onChange={(value: any) => onChangeMaxLimits(index, 'CLTV', value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                  <td rowSpan={3 * item.limits.length + 1} className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(item.loanAmount.min)}
                        origin={convertNullToBlank(item.loanAmount.min)}
                        content={getPrice3decimal(item.loanAmount.min)}
                        onChange={(value: any) => onChangeMinMaxLoanAmount(index, 'min', value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                  <td rowSpan={3 * item.limits.length + 1} className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(item.loanAmount.max)}
                        origin={convertNullToBlank(item.loanAmount.max)}
                        content={getPrice3decimal(item.loanAmount.max)}
                        onChange={(value: any) => onChangeMinMaxLoanAmount(index, 'max', value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                  <td rowSpan={3 * item.limits.length + 1} className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={item.minFICO}
                        origin={item.minFICO}
                        content={item.minFICO}
                        onChange={(value: any) => onChangeMinFICO(index, value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                </tr>

                {item.limits.map((limit, idx) => (
                  <Fragment key={`${index}-${idx}`}>
                    <tr>
                      {['from', 'to'].map((key, index1) => (
                        <td rowSpan={3} key={`${index}-${idx}-${index1}`} className={`border whitespace-nowrap p-1`}>
                          <div className="flex justify-center">
                            <PlainInput
                              value={(limit.FICO as any)[key]}
                              origin={(limit.FICO as any)[key]}
                              content={(limit.FICO as any)[key]}
                              onChange={(value: any) => onChangeFICO(index, idx, key, value)}
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                      ))}

                      <td className={`border whitespace-nowrap p-1`}>Purchase</td>

                      {['min', 'max'].map((key, index1) => (
                        <td key={`${index}-${idx}-${index1}`} className={`border whitespace-nowrap p-1`}>
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank((limit.loanAmount.purchase as any)[key])}
                              origin={convertNullToBlank((limit.loanAmount.purchase as any)[key])}
                              content={convertNullToBlankValueToDecimal((limit.loanAmount.purchase as any)[key])}
                              onChange={(value: any) => onChangeLoanAmountOverly(index, idx, 'purchase', key, value)}
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                      ))}
                      {minDscrMonthsReserveKeys.map((key, index1) => (
                        <td key={`${index}-${idx}-${index1}`} className={`border whitespace-nowrap p-1`}>
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank((limit as any)[key].purchase)}
                              origin={convertNullToBlank((limit as any)[key].purchase)}
                              content={convertNullToBlank((limit as any)[key].purchase)}
                              onChange={(value: any) =>
                                onChangeMinDscrMonthsReserve(index, idx, 'purchase', key, value)
                              }
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                      ))}
                      {['min', 'max'].map((key, index1) => (
                        <td key={`${index}-${idx}-${index1}`} className={`border whitespace-nowrap p-1`}>
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank(limit.LTV.purchase.ltv[key as 'min' | 'max'])}
                              origin={convertNullToBlank(limit.LTV.purchase.ltv[key as 'min' | 'max'])}
                              content={convertNullToBlank(limit.LTV.purchase.ltv[key as 'min' | 'max'])}
                              onChange={(value: any) =>
                                onChangeLtvLimit(index, idx, 'purchase', key as 'min' | 'max', value)
                              }
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                      ))}
                      <td className={`border whitespace-nowrap p-1`}>
                        <div className="flex justify-center">
                          <PlainInput
                            value={convertNullToBlank(limit.LTV.purchase.cltv)}
                            origin={convertNullToBlank(limit.LTV.purchase.cltv)}
                            content={convertNullToBlank(limit.LTV.purchase.cltv)}
                            onChange={(value: any) => onChangeCLTVLimit(index, idx, 'purchase', value)}
                            disabled={!isTemplate}
                            className="w-full"
                          />
                        </div>
                      </td>

                      {isTemplate && !readOnlyValue && (
                        <td rowSpan={3} className="border p-1">
                          <div className="flex justify-center">
                            <div
                              className="w-fit p-1 transition-all duration-200 hover:cursor-pointer hover-shadow1 rounded"
                              onClick={() => removeLtvLimit(index, idx)}
                            >
                              <TrashIcon className="w-4 h-4 hover:cursor-pointer text-red-700" />
                            </div>
                          </div>
                        </td>
                      )}
                    </tr>
                    <tr>
                      <td className={`border whitespace-nowrap p-1`}>No-Cashout</td>

                      {['min', 'max'].map((key, index1) => (
                        <td key={`${index}-${idx}-${index1}`} className={`border whitespace-nowrap p-1`}>
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank((limit.loanAmount.nocashout as any)[key])}
                              origin={convertNullToBlank((limit.loanAmount.nocashout as any)[key])}
                              content={convertNullToBlankValueToDecimal((limit.loanAmount.nocashout as any)[key])}
                              onChange={(value: any) => onChangeLoanAmountOverly(index, idx, 'nocashout', key, value)}
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                      ))}
                      {minDscrMonthsReserveKeys.map((key, index1) => (
                        <td key={`${index}-${idx}-${index1}`} className={`border whitespace-nowrap p-1`}>
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank((limit as any)[key].nocashout)}
                              origin={convertNullToBlank((limit as any)[key].nocashout)}
                              content={convertNullToBlank((limit as any)[key].nocashout)}
                              onChange={(value: any) =>
                                onChangeMinDscrMonthsReserve(index, idx, 'nocashout', key, value)
                              }
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                      ))}
                      {['min', 'max'].map((key, index1) => (
                        <td key={`${index}-${idx}-${index1}`} className={`border whitespace-nowrap p-1`}>
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank(limit.LTV.nocashout.ltv[key as 'min' | 'max'])}
                              origin={convertNullToBlank(limit.LTV.nocashout.ltv[key as 'min' | 'max'])}
                              content={convertNullToBlank(limit.LTV.nocashout.ltv[key as 'min' | 'max'])}
                              onChange={(value: any) =>
                                onChangeLtvLimit(index, idx, 'nocashout', key as 'min' | 'max', value)
                              }
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                      ))}
                      <td className={`border whitespace-nowrap p-1`}>
                        <div className="flex justify-center">
                          <PlainInput
                            value={convertNullToBlank(limit.LTV.nocashout.cltv)}
                            origin={convertNullToBlank(limit.LTV.nocashout.cltv)}
                            content={convertNullToBlank(limit.LTV.nocashout.cltv)}
                            onChange={(value: any) => onChangeCLTVLimit(index, idx, 'nocashout', value)}
                            disabled={!isTemplate}
                            className="w-full"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className={`border whitespace-nowrap p-1`}>Cashout</td>

                      {['min', 'max'].map((key, index1) => (
                        <td key={`${index}-${idx}-${index1}`} className={`border whitespace-nowrap p-1`}>
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank((limit.loanAmount.cashout as any)[key])}
                              origin={convertNullToBlank((limit.loanAmount.cashout as any)[key])}
                              content={convertNullToBlankValueToDecimal((limit.loanAmount.cashout as any)[key])}
                              onChange={(value: any) => onChangeLoanAmountOverly(index, idx, 'cashout', key, value)}
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                      ))}
                      {minDscrMonthsReserveKeys.map((key, index1) => (
                        <td key={`${index}-${idx}-${index1}`} className={`border whitespace-nowrap p-1`}>
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank((limit as any)[key].cashout)}
                              origin={convertNullToBlank((limit as any)[key].cashout)}
                              content={convertNullToBlank((limit as any)[key].cashout)}
                              onChange={(value: any) => onChangeMinDscrMonthsReserve(index, idx, 'cashout', key, value)}
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                      ))}
                      {['min', 'max'].map((key, index1) => (
                        <td key={`${index}-${idx}-${index1}`} className={`border whitespace-nowrap p-1`}>
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank(limit.LTV.cashout.ltv[key as 'min' | 'max'])}
                              origin={convertNullToBlank(limit.LTV.cashout.ltv[key as 'min' | 'max'])}
                              content={convertNullToBlank(limit.LTV.cashout.ltv[key as 'min' | 'max'])}
                              onChange={(value: any) =>
                                onChangeLtvLimit(index, idx, 'cashout', key as 'min' | 'max', value)
                              }
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                      ))}
                      <td className={`border whitespace-nowrap p-1`}>
                        <div className="flex justify-center">
                          <PlainInput
                            value={convertNullToBlank(limit.LTV.cashout.cltv)}
                            origin={convertNullToBlank(limit.LTV.cashout.cltv)}
                            content={convertNullToBlank(limit.LTV.cashout.cltv)}
                            onChange={(value: any) => onChangeCLTVLimit(index, idx, 'cashout', value)}
                            disabled={!isTemplate}
                            className="w-full"
                          />
                        </div>
                      </td>
                    </tr>
                  </Fragment>
                ))}
              </Fragment>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
