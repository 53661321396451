import { ArchiveBoxXMarkIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { PlainInput } from 'components/PlainInput'
import type { ICommercialOnlyProgrammed, IProgram, IRehabBudgetTypeLtvMaxLimits } from 'config'
import { LoadingStatus } from 'pages/RateSheetOverview'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Input, Select } from 'stories/components'
import { confirm, isEmpty, removeComma } from 'utils'

import { RehabBudgetTypes } from '../constants'
import { convertNullToBlank, convertNullToBlankValueToDecimal } from '../logic'

interface IProps {
  isTemplate: boolean
  program: IProgram
  leverageData: IRehabBudgetTypeLtvMaxLimits[]
  isLoading: boolean
  loading: string
  title: string
  onSave: (data: IRehabBudgetTypeLtvMaxLimits[], limitKey: string) => void
}

export const RehabBudgetLtvmaxLimits = (props: IProps) => {
  const { program, leverageData, isLoading, isTemplate, loading, onSave } = props

  const [data, setData] = useState<IRehabBudgetTypeLtvMaxLimits[]>([])
  const [edit, setEdit] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  const [programData, setProgramData] = useState<ICommercialOnlyProgrammed>()
  const [expOptions, setExpOptions] = useState<string[]>([])

  useEffect(() => {
    const experienceOptions: string[] = []

    for (let i = 0; i <= 100; i++) experienceOptions.push(`${i}`)

    setExpOptions(experienceOptions)
  }, [])

  useEffect(() => {
    if (!program) return
    if (!leverageData) return

    setData(leverageData)

    const errs: string[] = []
    leverageData.forEach(() => errs.push(''))
    setErrors(errs)

    setProgramData(program.OtherTypeProgramData as ICommercialOnlyProgrammed)
    setEdit(false)
  }, [program, leverageData])

  useEffect(() => {
    if (!loading) setEdit(false)
  }, [loading])

  const addLimit = () => {
    const newData = cloneDeep(data)

    if (newData.length === 3) {
      toast(`All options are already included.`, { type: 'info' })
      return
    }

    newData.unshift({
      value: '',
      limits: [],
    })

    setData(newData)
    setEdit(true)
  }

  const removeLimit = async (index: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this row?
        <br />
        <span className="text-gray-600 text-base">
          Rehab Budget Type: {RehabBudgetTypes[data[index].value] || 'N/A'}
        </span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const newData = cloneDeep(data)

    newData.splice(index, 1)

    setData(newData)
    setEdit(true)
  }

  const removeFICORange = async (index: number, id: number, idx: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this row?
        <br />
        <span className="text-gray-600 text-base">
          Rehab Budget Type: {RehabBudgetTypes[data[index].value] || 'N/A'}
        </span>
        <br />
        <span className="text-gray-600 text-base">
          Experience: {data[index].limits[id].expRange.from} - {data[index].limits[id].expRange.to}
        </span>
        <br />
        <span className="text-gray-600 text-base">
          FICO: {data[index].limits[id].limits[idx].FICO.from} - {data[index].limits[id].limits[idx].FICO.to}
        </span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const newData = cloneDeep(data)

    newData[index].limits[id].limits.splice(idx, 1)

    if (!newData[index].limits[id].limits.length) {
      newData[index].limits.splice(id, 1)

      if (!newData[index].limits.length) newData.splice(index, 1)
      else {
        const targetData = newData[index]

        for (let i = 1; i < targetData.limits.length; i++) {
          if (targetData.limits[i].expRange.from - targetData.limits[i - 1].expRange.to !== 1)
            targetData.limits[i].expRange.from = targetData.limits[i - 1].expRange.to + 1

          if (targetData.limits[i].expRange.from > targetData.limits[i].expRange.to)
            targetData.limits[i].expRange.to = targetData.limits[i].expRange.from
        }

        newData[index] = targetData
      }
    }

    setData(newData)
    setEdit(true)
  }

  const onChangeRehabBudgetType = (index: number, value: string) => {
    const newData = cloneDeep(data)
    const newErrors = cloneDeep(errors)

    if (newData.find((item) => item.value === value)) {
      toast(`Please choose another option.`, {
        type: 'info',
      })
      return
    }

    if (!isEmpty(value)) {
      newErrors[index] = ''
      setErrors(newErrors)
    }

    newData[index].value = value

    setData(newData)
    setEdit(true)
  }

  const onChangeLtvLimit = (
    index: number,
    id: number,
    idx: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: string,
    value: any,
  ) => {
    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if ((newData[index].limits[id].limits[idx].LTV[loanPurpose] as any)[key] === value) return
    ;(newData[index].limits[id].limits[idx].LTV[loanPurpose] as any)[key] = value

    setData(newData)
    setEdit(true)
  }

  const onAddExperienceRange = (index: number) => {
    const newData = cloneDeep(data)

    if (!!data[index].limits.length && data[index].limits[data[index].limits.length - 1].expRange.to === 100) {
      toast(`All options are already included.`, { type: 'info' })
      return
    }

    newData[index].limits.push({
      expRange: {
        from: data[index].limits.length === 0 ? 0 : data[index].limits[data[index].limits.length - 1].expRange.to + 1,
        to: data[index].limits.length === 0 ? 0 : data[index].limits[data[index].limits.length - 1].expRange.to + 1,
      },
      loanAmount: { min: NaN, max: NaN },
      limits: [
        {
          FICO: { from: 0, to: 1000 },
          LTV: {
            purchase: {
              aivLtv: NaN,
              arvLtv: NaN,
              ltc: NaN,
              ltp: NaN,
            },
            nocashout: {
              aivLtv: NaN,
              arvLtv: NaN,
              ltc: NaN,
              ltp: NaN,
            },
            cashout: {
              aivLtv: NaN,
              arvLtv: NaN,
              ltc: NaN,
              ltp: NaN,
            },
          },
          loanAmount: {
            purchase: { min: NaN, max: NaN },
            nocashout: { min: NaN, max: NaN },
            cashout: { min: NaN, max: NaN },
          },
          minMonthsReserve: {
            purchase: 0,
            nocashout: 0,
            cashout: 0,
          },
        },
      ],
    })

    setData(newData)
    setEdit(true)
  }

  const addFICORange = (index: number, id: number) => {
    const newData = cloneDeep(data)

    newData[index].limits[id].limits.push({
      FICO: {
        from: newData[index].limits[id].limits[newData[index].limits[id].limits.length - 1].FICO.from,
        to: newData[index].limits[id].limits[newData[index].limits[id].limits.length - 1].FICO.to,
      },
      LTV: {
        purchase: {
          aivLtv: NaN,
          arvLtv: NaN,
          ltc: NaN,
          ltp: NaN,
        },
        nocashout: {
          aivLtv: NaN,
          arvLtv: NaN,
          ltc: NaN,
          ltp: NaN,
        },
        cashout: {
          aivLtv: NaN,
          arvLtv: NaN,
          ltc: NaN,
          ltp: NaN,
        },
      },
      loanAmount: {
        purchase: { min: NaN, max: NaN },
        nocashout: { min: NaN, max: NaN },
        cashout: { min: NaN, max: NaN },
      },
      minMonthsReserve: {
        purchase: 0,
        nocashout: 0,
        cashout: 0,
      },
    })

    setData(newData)
    setEdit(true)
  }

  const onChangeLoanAmountOverly = (
    index: number,
    idx: number,
    id: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: string,
    value: any,
  ) => {
    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if ((newData[index].limits[idx].limits[id].loanAmount[loanPurpose] as any)[key] == value) return
    ;(newData[index].limits[idx].limits[id].loanAmount[loanPurpose] as any)[key] = value

    setData(newData)
    setEdit(true)
  }

  const submit = () => {
    const newErrs = cloneDeep(errors)

    let hasError = false

    data.forEach((item, index) => {
      if (isEmpty(item.value)) {
        hasError = true
        newErrs[index] = 'Required field'
      }
    })

    if (hasError) {
      setErrors(newErrs)
      toast(`Required fields exist!`, { type: 'error' })
      return
    }

    onSave(data, 'rehabBudgetTypeLtvLimits')
  }

  const onChangeExperienceRange = (index: number, id: number, value: number, startValue = false) => {
    const newData = cloneDeep(data)

    const targetData = newData[index]

    if (startValue) {
      targetData.limits[0].expRange.from = value

      if (targetData.limits[0].expRange.from > targetData.limits[0].expRange.to)
        targetData.limits[0].expRange.to = targetData.limits[0].expRange.from
    } else {
      if (targetData.limits[id].expRange.from > value) return

      targetData.limits[id].expRange.to = value
    }

    if (id < targetData.limits.length - 1)
      for (let i = id + 1; i < targetData.limits.length; i++) {
        if (targetData.limits[i].expRange.from - targetData.limits[i - 1].expRange.to !== 1)
          targetData.limits[i].expRange.from = targetData.limits[i - 1].expRange.to + 1

        if (targetData.limits[i].expRange.from > targetData.limits[i].expRange.to)
          targetData.limits[i].expRange.to = targetData.limits[i].expRange.from
      }

    newData[index] = targetData

    if (!!newData[index].limits.length && newData[index].limits[newData[index].limits.length - 1].expRange.to > 100)
      return

    setData(newData)
    setEdit(true)
  }

  const onChangeFICO = (index: number, idx: number, id: number, key: string, value: any) => {
    value = removeComma(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if ((newData[index].limits[idx].limits[id].FICO as any)[key] === value) return
    ;(newData[index].limits[idx].limits[id].FICO as any)[key] = value

    setData(newData)
    setEdit(true)
  }

  const onChangeMinMaxLoanAmount = (index: number, id: number, key: 'min' | 'max', value: any) => {
    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    const newData = cloneDeep(data)

    if (newData[index].limits[id].loanAmount[key] === value) return
    newData[index].limits[id].loanAmount[key] = value

    setData(newData)
    setEdit(true)
  }

  const onChangeMinMonthsReserve = (
    index: number,
    idx: number,
    id: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    value: any,
  ) => {
    value = removeComma(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if (newData[index].limits[idx].limits[id].minMonthsReserve[loanPurpose] === value) return
    newData[index].limits[idx].limits[id].minMonthsReserve[loanPurpose] = value

    setData(newData)
    setEdit(true)
  }

  const renderLtvMaxLimits = useMemo(() => {
    if (!programData || data.length === 0)
      return (
        <div className="w-full flex justify-center items-center mt-10">
          <div className="flex flex-col items-center gap-1">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Limits</span>
          </div>
        </div>
      )

    return data.map((item, index) => (
      <div key={index} className="border px-3 pt-6 pb-3 rounded-md mb-4 relative">
        <div className="flex justify-between items-start">
          <Select
            id="rehabBudgetType"
            options={RehabBudgetTypes}
            hasDefaultOption={true}
            title="Rehab Budget Type"
            value={item.value}
            error={errors[index]}
            readOnly={!isTemplate}
            className="w-[200px]"
            onChange={(v) => onChangeRehabBudgetType(index, v)}
          />

          {isTemplate && (
            <div
              className="transition-all duration-200 w-fit p-1 hover:cursor-pointer hover-shadow1 rounded"
              onClick={() => removeLimit(index)}
            >
              <TrashIcon className="w-4 h-4 hover:cursor-pointer text-red-700" />
            </div>
          )}
        </div>

        <div className="overflow-x-auto">
          <table className="table w-full min-w-max text-center text-sm">
            <thead className="bg-gray-100">
              <tr>
                <th rowSpan={2} className="border p-1 whitespace-nowrap">
                  Experience
                </th>
                <th rowSpan={2} className="border p-1 w-[130px] whitespace-nowrap">
                  Min Loan Amount
                </th>
                <th rowSpan={2} className="border p-1 w-[130px] whitespace-nowrap">
                  Max Loan Amount
                </th>
                <th colSpan={2} className="border p-1">
                  FICO
                </th>
                <th rowSpan={2} className="border p-1 whitespace-nowrap">
                  Loan Purpose
                </th>
                <th colSpan={2} className="border p-1 whitespace-nowrap">
                  Loan Amount
                </th>
                <th rowSpan={2} className="border p-1 w-[100px]">
                  Min Months Reserve
                </th>
                <th colSpan={4} className="border p-1 whitespace-nowrap">
                  Max LTVs
                </th>

                {isTemplate && (
                  <th rowSpan={2} className="border p-1">
                    Action
                  </th>
                )}
              </tr>
              <tr>
                <th className="border p-1 w-[90px]">From</th>
                <th className="border p-1 w-[90px]">To</th>
                <th className="border p-1 w-[120px]">From</th>
                <th className="border p-1 w-[120px]">To</th>
                <th className="border p-1 w-[90px]">AIV-LTV</th>
                <th className="border p-1 w-[90px]">ARV-LTV</th>
                <th className="border p-1 w-[90px]">LTC</th>
                <th className="border p-1 w-[90px]">LTP</th>
              </tr>
            </thead>

            <tbody>
              {item.limits.map((limit, id) => {
                return (
                  <Fragment key={`${index}-${id}`}>
                    <tr>
                      <td rowSpan={3 * limit.limits.length + 1} className="border p-1">
                        {isTemplate ? (
                          <div className="flex gap-2 items-center justify-center">
                            {id === 0 ? (
                              <Select
                                id="from"
                                title="From"
                                options={expOptions}
                                value={limit.expRange.from.toString()}
                                className="w-[65px] mb-[-16px]"
                                onChange={(value) => onChangeExperienceRange(index, id, Number(value), true)}
                              />
                            ) : (
                              <Input
                                title="From"
                                value={limit.expRange.from.toString()}
                                className="w-[65px] mb-[-16px]"
                                disabled={true}
                                onChange={() => {}}
                              />
                            )}
                            <Select
                              id="to"
                              title="To"
                              options={expOptions}
                              value={limit.expRange.to.toString()}
                              className="w-[65px] mb-[-16px]"
                              onChange={(value) => onChangeExperienceRange(index, id, Number(value))}
                            />
                          </div>
                        ) : (
                          <div className="flex justify-center">
                            {limit.expRange.from} - {limit.expRange.to}
                          </div>
                        )}

                        {isTemplate && (
                          <div className="mt-5 flex justify-center w-full">
                            <span
                              className="cursor-pointer text-shade-blue hover:scale-105 transform duration-100 hover:underline"
                              onClick={() => addFICORange(index, id)}
                            >
                              Add FICO Range
                            </span>
                          </div>
                        )}
                      </td>

                      <td rowSpan={3 * limit.limits.length + 1} className="border p-1">
                        <div className="flex justify-center">
                          <PlainInput
                            value={convertNullToBlank(limit.loanAmount.min)}
                            origin={convertNullToBlank(limit.loanAmount.min)}
                            content={convertNullToBlankValueToDecimal(limit.loanAmount.min)}
                            onChange={(value: any) => onChangeMinMaxLoanAmount(index, id, 'min', value)}
                            disabled={!isTemplate}
                            className="w-full"
                          />
                        </div>
                      </td>
                      <td rowSpan={3 * limit.limits.length + 1} className="border p-1">
                        <div className="flex justify-center">
                          <PlainInput
                            value={convertNullToBlank(limit.loanAmount.max)}
                            origin={convertNullToBlank(limit.loanAmount.max)}
                            content={convertNullToBlankValueToDecimal(limit.loanAmount.max)}
                            onChange={(value: any) => onChangeMinMaxLoanAmount(index, id, 'max', value)}
                            disabled={!isTemplate}
                            className="w-full"
                          />
                        </div>
                      </td>
                    </tr>

                    {limit.limits.map((sub, idx) => (
                      <Fragment key={`${index}-${id}-${idx}`}>
                        <tr>
                          {['from', 'to'].map((key, index1) => (
                            <td
                              rowSpan={3}
                              key={`${index}-${id}-${idx}-${index1}`}
                              className={`border whitespace-nowrap p-1`}
                            >
                              <div className="flex justify-center">
                                <PlainInput
                                  value={(sub.FICO as any)[key]}
                                  origin={(sub.FICO as any)[key]}
                                  content={(sub.FICO as any)[key]}
                                  onChange={(value: any) => onChangeFICO(index, id, idx, key, value)}
                                  disabled={!isTemplate}
                                  className="w-full"
                                />
                              </div>
                            </td>
                          ))}
                          <td className="border p-1">Purchase</td>
                          {['min', 'max'].map((key, index1) => (
                            <td
                              key={`${index}-${id}-${idx}-${index1}`}
                              className={`border whitespace-nowrap p-1 ${isTemplate ? 'bg-gray-50' : ''}`}
                            >
                              <div className="flex justify-center">
                                <PlainInput
                                  value={convertNullToBlank((sub.loanAmount.purchase as any)[key])}
                                  origin={convertNullToBlank((sub.loanAmount.purchase as any)[key])}
                                  content={convertNullToBlankValueToDecimal((sub.loanAmount.purchase as any)[key])}
                                  onChange={(value: any) =>
                                    onChangeLoanAmountOverly(index, id, idx, 'purchase', key, value)
                                  }
                                  disabled={!isTemplate}
                                  className="w-full"
                                />
                              </div>
                            </td>
                          ))}
                          <td className={`p-1 border ${!isTemplate ? '' : 'bg-gray-50'}`}>
                            <div className="flex justify-center">
                              <PlainInput
                                value={convertNullToBlank(sub.minMonthsReserve.purchase)}
                                origin={convertNullToBlank(sub.minMonthsReserve.purchase)}
                                content={convertNullToBlank(sub.minMonthsReserve.purchase)}
                                disabled={!isTemplate}
                                onChange={(value: any) => onChangeMinMonthsReserve(index, id, idx, 'purchase', value)}
                                className="w-full"
                              />
                            </div>
                          </td>
                          {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, index1) => (
                            <td
                              key={`${index}-${id}-${idx}-${index1}`}
                              className={`p-1 border ${
                                isEmpty(program.OtherTypeProgramData.ltvLimits.purchase[key]) || !isTemplate
                                  ? ''
                                  : 'bg-gray-50'
                              }`}
                            >
                              <div className="flex justify-center">
                                <PlainInput
                                  value={convertNullToBlank((sub.LTV.purchase as any)[key])}
                                  origin={convertNullToBlank((sub.LTV.purchase as any)[key])}
                                  content={convertNullToBlank((sub.LTV.purchase as any)[key])}
                                  disabled={
                                    isEmpty(program.OtherTypeProgramData.ltvLimits.purchase[key]) || !isTemplate
                                  }
                                  onChange={(value: any) => onChangeLtvLimit(index, id, idx, 'purchase', key, value)}
                                  className="w-full"
                                />
                              </div>
                            </td>
                          ))}
                          {isTemplate && (
                            <td rowSpan={3} className="border p-1">
                              <div className="flex justify-center">
                                <div
                                  className="w-fit p-1 transition-all duration-200 hover:cursor-pointer hover-shadow1 rounded"
                                  onClick={() => removeFICORange(index, id, idx)}
                                >
                                  <TrashIcon className="w-4 h-4 hover:cursor-pointer text-red-700" />
                                </div>
                              </div>
                            </td>
                          )}
                        </tr>

                        <tr>
                          <td className="border p-1">No-Cashout</td>

                          {['min', 'max'].map((key, index1) => (
                            <td
                              key={`${index}-${id}-${idx}-${index1}`}
                              className={`border whitespace-nowrap p-1 ${isTemplate ? 'bg-gray-50' : ''}`}
                            >
                              <div className="flex justify-center">
                                <PlainInput
                                  value={convertNullToBlank((sub.loanAmount.nocashout as any)[key])}
                                  origin={convertNullToBlank((sub.loanAmount.nocashout as any)[key])}
                                  content={convertNullToBlankValueToDecimal((sub.loanAmount.nocashout as any)[key])}
                                  onChange={(value: any) =>
                                    onChangeLoanAmountOverly(index, id, idx, 'nocashout', key, value)
                                  }
                                  disabled={!isTemplate}
                                  className="w-full"
                                />
                              </div>
                            </td>
                          ))}
                          <td className={`p-1 border ${!isTemplate ? '' : 'bg-gray-50'}`}>
                            <div className="flex justify-center">
                              <PlainInput
                                value={convertNullToBlank(sub.minMonthsReserve.nocashout)}
                                origin={convertNullToBlank(sub.minMonthsReserve.nocashout)}
                                content={convertNullToBlank(sub.minMonthsReserve.nocashout)}
                                disabled={!isTemplate}
                                onChange={(value: any) => onChangeMinMonthsReserve(index, id, idx, 'nocashout', value)}
                                className="w-full"
                              />
                            </div>
                          </td>
                          {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, index1) => (
                            <td
                              key={`${index}-${id}-${idx}-${index1}`}
                              className={`p-1 border ${
                                isEmpty(program.OtherTypeProgramData.ltvLimits.nocashout[key]) || !isTemplate
                                  ? ''
                                  : 'bg-gray-50'
                              }`}
                            >
                              <div className="flex justify-center">
                                <PlainInput
                                  value={convertNullToBlank((sub.LTV.nocashout as any)[key])}
                                  origin={convertNullToBlank((sub.LTV.nocashout as any)[key])}
                                  content={convertNullToBlank((sub.LTV.nocashout as any)[key])}
                                  disabled={
                                    isEmpty(program.OtherTypeProgramData.ltvLimits.nocashout[key]) || !isTemplate
                                  }
                                  onChange={(value: any) => onChangeLtvLimit(index, id, idx, 'nocashout', key, value)}
                                  className="w-full"
                                />
                              </div>
                            </td>
                          ))}
                        </tr>

                        <tr>
                          <td className="border p-1">Cashout</td>

                          {['min', 'max'].map((key, index1) => (
                            <td
                              key={`${index}-${id}-${idx}-${index1}`}
                              className={`border whitespace-nowrap p-1 ${isTemplate ? 'bg-gray-50' : ''}`}
                            >
                              <div className="flex justify-center">
                                <PlainInput
                                  value={convertNullToBlank((sub.loanAmount.cashout as any)[key])}
                                  origin={convertNullToBlank((sub.loanAmount.cashout as any)[key])}
                                  content={convertNullToBlankValueToDecimal((sub.loanAmount.cashout as any)[key])}
                                  onChange={(value: any) =>
                                    onChangeLoanAmountOverly(index, id, idx, 'cashout', key, value)
                                  }
                                  disabled={!isTemplate}
                                  className="w-full"
                                />
                              </div>
                            </td>
                          ))}
                          <td className={`p-1 border ${!isTemplate ? '' : 'bg-gray-50'}`}>
                            <div className="flex justify-center">
                              <PlainInput
                                value={convertNullToBlank(sub.minMonthsReserve.cashout)}
                                origin={convertNullToBlank(sub.minMonthsReserve.cashout)}
                                content={convertNullToBlank(sub.minMonthsReserve.cashout)}
                                disabled={!isTemplate}
                                onChange={(value: any) => onChangeMinMonthsReserve(index, id, idx, 'cashout', value)}
                                className="w-full"
                              />
                            </div>
                          </td>
                          {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, index1) => (
                            <td
                              key={`${index}-${id}-${idx}-${index1}`}
                              className={`p-1 border ${
                                isEmpty(program.OtherTypeProgramData.ltvLimits.cashout[key]) || !isTemplate
                                  ? ''
                                  : 'bg-gray-50'
                              }`}
                            >
                              <div className="flex justify-center">
                                <PlainInput
                                  value={convertNullToBlank((sub.LTV.cashout as any)[key])}
                                  origin={convertNullToBlank((sub.LTV.cashout as any)[key])}
                                  content={convertNullToBlank((sub.LTV.cashout as any)[key])}
                                  disabled={isEmpty(program.OtherTypeProgramData.ltvLimits.cashout[key]) || !isTemplate}
                                  onChange={(value: any) => onChangeLtvLimit(index, id, idx, 'cashout', key, value)}
                                  className="w-full"
                                />
                              </div>
                            </td>
                          ))}
                        </tr>
                      </Fragment>
                    ))}
                  </Fragment>
                )
              })}
            </tbody>
          </table>
        </div>

        {isTemplate && (
          <div className="flex justify-end">
            <div
              className="flex items-center gap-2 cursor-pointer w-fit text-shade-blue transition-all duration-200 hover:border-b hover:border-b-shade-blue pr-1 h-6 mt-3"
              onClick={() => onAddExperienceRange(index)}
            >
              <PlusIcon className="w-4 h-4" />
              <span>Add</span>
            </div>
          </div>
        )}
      </div>
    ))
  }, [data, errors])

  return (
    <div className="relative">
      <LayoutLoading show={LoadingStatus.LEVERAGE_LIMIT === loading || isLoading} />

      <div className="px-2 mt-2">
        {isTemplate && (
          <div className={`${edit ? 'justify-between' : 'justify-end'} flex items-center`}>
            {edit && (
              <Button loading={LoadingStatus.LEVERAGE_LIMIT === loading} onClick={submit} className="pt-1.5 pb-1.5">
                Save
              </Button>
            )}

            <div
              className="flex items-center gap-2 cursor-pointer w-fit text-shade-blue transition-all duration-200 hover:border-b hover:border-b-shade-blue pr-1 h-6"
              onClick={addLimit}
            >
              <PlusIcon className="w-4 h-4" />
              <span>Add LTV Limit</span>
            </div>
          </div>
        )}

        <div className="mt-3">{renderLtvMaxLimits}</div>
      </div>
    </div>
  )
}
