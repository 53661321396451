import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import type { IinEligibleProduct, ILtvValues, InputType, IProgramLoanDetails } from 'config'
import { Tooltip } from 'flowbite-react'
import { useEffect, useMemo, useState } from 'react'
import { ButtonGroup, Modal } from 'stories/components'
import { isHTML } from 'utils'

import { convertLoanDetailStatus, getLoanDetailsValue, isNonDSCRProduct } from '../logic'
import { LtvValuesAndLimits } from '../LtvValuesAndLimits/LtvValuesAndLimits'

interface Props {
  loanFields: Record<string, InputType>
  inEligibleProducts: IinEligibleProduct
  programsLoanDetails: IProgramLoanDetails
  isOpen: boolean
  visibleFields: string[]
  programs?: Record<string, any>[]
  onClose: () => void
}

const UndefinedLoanDetailFields: string[] = [
  'AIV-LTV',
  'ARV-LTV',
  'LTC',
  'LTP',
  'LTV',
  'CLTV',
  'Estimated Closing Amount to Borrower',
  'Number of Properties',
  'Loan Purpose',
  'Rehab Budget Type',
]

const NonDSCROnlyFields: string[] = ['AIV-LTV', 'ARV-LTV', 'LTC', 'LTP', 'Rehab Budget Type']

export const InEligibleModal = (props: Props) => {
  const { loanFields, inEligibleProducts, programsLoanDetails, visibleFields, programs = [], isOpen, onClose } = props

  const [program, setProgram] = useState('')
  const [ltvValues, setLtvValues] = useState<ILtvValues | undefined>(undefined)
  const [ltvLimits, setLtvLimits] = useState<ILtvValues | undefined>(undefined)

  useEffect(() => {
    setProgram(Object.keys(inEligibleProducts)[0])
  }, [inEligibleProducts])

  useEffect(() => {
    if (programs.length === 0) return

    const targetProgram = programs.find((item) => item.Name === program)

    if (targetProgram?.LtvLimit) {
      const ltvValueData: ILtvValues = {
        aivLtv: targetProgram.LtvLimit.aiv_ltv,
        arvLtv: targetProgram.LtvLimit.arv_ltv,
        ltc: targetProgram.LtvLimit.ltc,
        ltp: targetProgram.LtvLimit.ltp,
      }

      const ltvLimitData: ILtvValues = {
        aivLtv: targetProgram.LtvLimit.max_aiv_ltv,
        arvLtv: targetProgram.LtvLimit.max_arv_ltv,
        ltc: targetProgram.LtvLimit.max_ltc,
        ltp: targetProgram.LtvLimit.max_ltp,
      }

      setLtvValues(ltvValueData)
      setLtvLimits(ltvLimitData)
    }
  }, [program])

  const renderTable = useMemo(() => {
    if (!programsLoanDetails[program]) return <></>

    return (
      <table className="table w-fit mt-2 text-sm text-center">
        <thead className="bg-gray-100">
          <tr>
            <th className="border p-2">Field</th>
            <th className="border p-2">Value</th>
            <th className="border p-2">Description</th>
          </tr>
        </thead>

        <tbody>
          {Object.keys(programsLoanDetails[program]).map((key, index) => {
            const targetField = programsLoanDetails[program][key]
            if (!visibleFields.includes(key) && !UndefinedLoanDetailFields.includes(key)) return <></>
            if (loanFields.transactionType.value !== 'Refinance' && key === 'Estimated Closing Amount to Borrower')
              return <></>
            if (isNonDSCRProduct(loanFields.productType.value)) {
              if (key === 'LTV') return <></>
            } else {
              if (NonDSCROnlyFields.includes(key)) return <></>
            }
            return (
              <tr key={index} className="border">
                <td className="border p-2">{loanFields[key]?.title || key}</td>
                <td className="border p-2">
                  {UndefinedLoanDetailFields.includes(key) ? targetField.value : getLoanDetailsValue(key, loanFields)}
                </td>
                <td className="border p-2">
                  <div className="flex items-center justify-end gap-2 cursor-pointer min-w-full">
                    {targetField.isPassed ? (
                      <div className="flex items-center justify-end gap-2">
                        <span>{convertLoanDetailStatus(targetField.limit)}</span>
                        <CheckIcon className="w-5 h-5 cursor-pointer text-green-500" />
                      </div>
                    ) : (
                      <Tooltip
                        content={
                          <div>
                            {targetField.ineligibleText}
                            {targetField.calcHistory && (
                              <ul className="text-left list-disc pl-4 font-weight-400">
                                {targetField.calcHistory.map((item, index1) => (
                                  <li key={`${index}-${index1}`}>{item}</li>
                                ))}
                              </ul>
                            )}
                          </div>
                        }
                        placement="top"
                      >
                        <div className="flex items-center gap-2 cursor-pointer">
                          <span>{convertLoanDetailStatus(targetField.limit)}</span>
                          <XMarkIcon className="w-5 h-5 text-rose-700" />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </td>
              </tr>
            )
          })}

          {inEligibleProducts[program].map(
            (
              item: {
                name: string
                value: string
                description: string
                ltvMaxCalcHistory?: string[]
              },
              index,
            ) => {
              return (
                <tr key={index} className="border">
                  <td className="border p-2">{item.name}</td>
                  <td className="border p-2">
                    {isHTML(item.value) ? (
                      <span className="dangerouslySetInnerHTML">
                        <div dangerouslySetInnerHTML={{ __html: item.value }}></div>
                      </span>
                    ) : (
                      item.value
                    )}
                  </td>
                  <td className="border p-2">
                    <div className="flex justify-end min-w-full">
                      <Tooltip
                        content={
                          <div>
                            {item.description}
                            {item.ltvMaxCalcHistory && (
                              <ul className="text-left list-disc pl-4 font-weight-400">
                                {item.ltvMaxCalcHistory.map((value, index1) => {
                                  return <li key={`${index}-${index1}`}>{value}</li>
                                })}
                              </ul>
                            )}
                          </div>
                        }
                        placement="top"
                      >
                        <XMarkIcon className="w-5 h-5 text-rose-700 hover:cursor-pointer" />
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              )
            },
          )}
        </tbody>
      </table>
    )
  }, [inEligibleProducts, programsLoanDetails, program, visibleFields, loanFields])

  return (
    <div>
      <Modal title="Ineligible Programs" isOpen={isOpen} onClose={onClose} titleCancel={''} titleOkay={''}>
        <div>
          <ButtonGroup
            title={Object.keys(inEligibleProducts)}
            value={program}
            onChange={(value) => setProgram(value)}
          />

          {ltvValues && ltvLimits && (
            <div className="mt-2 mb-4 max-w-[600px]">
              <LtvValuesAndLimits values={ltvValues} limits={ltvLimits} />
            </div>
          )}

          <div className="flex justify-center">{renderTable}</div>
        </div>
      </Modal>
    </div>
  )
}
