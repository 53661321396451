import {
  ArrowPathIcon,
  ChevronLeftIcon,
  InboxArrowDownIcon,
  ListBulletIcon,
  PencilSquareIcon,
  PlusIcon,
  Square2StackIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import {
  type ICommercialOnlyProgrammed,
  type IExperienceTierLimit,
  type IHistory,
  type INormalLLPA,
  type IProduct,
  type IProgram,
  type IPropertyTypeLtvAdj,
  type IRange,
  type IRangeLLPA,
  type IRatePriceAdjustment,
  type IRateSheet,
  type IStateMSA,
  type IStateTier,
  ILoanPurposeLTVValues,
  IMasterProgram,
  IMatrixLTVData,
  IStaticTable,
} from 'config'
import { Tooltip } from 'flowbite-react'
import { createContext, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { store } from 'reducers'
import { removeMasterProgram, updateMasterProgram } from 'services/apis/masterProgram'
import {
  addNewProgram,
  addRatesheetHistory,
  addStateTier,
  clearRatesheetHistory,
  cloneProgram,
  getProgram,
  getRatesheet,
  getStateTier,
  removeMsaTierLtvAdjCondition,
  removeProgram,
  removeStateTier,
  saveBonusMargin,
  saveCommercialProductTypes,
  saveDscrLeverageData,
  saveExperienceTierLimit,
  saveMsaTierLtvAdjustment,
  saveNonDscrLeverageData,
  saveStateTierValues,
  saveTierLtvAdjustment,
  syncProgram,
  syncProgramTiers,
  syncProgramToGeneral,
  updateDscrOccupancyLtvReduction,
  updateLtvRatePriceRangeForNonDscr,
  updateMatrixLTVLimits,
  updateProductPower,
  updateProgramData,
  updateProgramInfo,
  updateProgramRestriction,
  updateProgramsOrder,
  updateRangeForDscr,
  updateRatesheet,
  updateStateTier,
} from 'services/apis/ratesheet'
import { svgLoading } from 'stories/assets'
import { Button } from 'stories/components'
import { AccountType } from 'stories/layouts'
import { confirm, isEmpty } from 'utils'
import { useTitle } from 'utils/pageTitle'

import { DscrLeverageAdjustments } from './DscrLeverageAdjustments'
import { DscrLeverageLimits } from './DscrLeverageLimits'
import { DscrOverlay } from './DscrOverlay'
import { CommercialDSCRSheet } from './ExcelSheets/CommercialDSCRSheet/CommercialDSCRSheet'
import { CommercialOnlyProgrammed } from './ExcelSheets/CommercialOnlyProgrammed'
import { Dscr } from './ExcelSheets/Dscr'
import { FastMoneyExcelSheet } from './ExcelSheets/FastMoneyExcelSheet'
import { HardMoneyExcelSheet } from './ExcelSheets/HardMoneyExcelSheet'
import { SoftMoneyExcelSheet } from './ExcelSheets/SoftMoneyExcelSheet'
import { InvestorInfo } from './InvestorInfo'
import { LoanProgram } from './LoanProgram'
import { LockDaysBankruptcy } from './LockDaysBankruptcy'
import { MatrixLTVTable } from './MatrixLTVTable'
import {
  CloneProgramModal,
  EditProductTypeModal,
  EditProgramInfoModal,
  MasterProgramModal,
  NewProgramModal,
  ProgramsOrderModal,
  SyncProgramModal,
  SyncTiersModal,
} from './Modals'
import { PrepaymentPenalty } from './PrepaymentPenalty'
import { ProgramMargin } from './PriceMargin'
import { RatesheetHistory } from './RatesheetHistory'
import { RatesheetProducts } from './RatesheetProducts'
import { RatesheetPrograms } from './RatesheetPrograms'
import { StateLTVMaxLimit } from './StateLTVMaxLimit'
import { StaticTable } from './StaticTable'

export enum ModalSetting {
  NEW_PROGRAM = 'NEW_PROGRAM',
  CLONE_PROGRAM = 'CLONE_PROGRAM',
  CHANGE_PROGRAMS_ORDER = 'CHANGE_PROGRAMS_ORDER',
  UPDATE_LOAN_AMOUNT_RANGE = 'UPDATE_LOAN_AMOUNT_RANGE',
  ADD_NEW_FICO = 'ADD_NEW_FICO',
  SYNC_PROGRAM_WITH_GENERAL = 'SYNC_PROGRAM_WITH_GENERAL',
  EDIT_PROGRAM_INFO = 'EDIT_PROGRAM_INFO',
  SYNC_TIERS = 'SYNC_TIERS',
  EDIT_PRODUCT_TYPE = 'EDIT_PRODUCT_TYPE',
  MASTER_PROGRAM = 'MASTER_PROGRAM',
}

export enum LoadingStatus {
  GET_RATESHEET = 'GET_RATESHEET',
  CLONE_PROGRAM = 'CLONE_PROGRAM',
  CHANGE_PROGRAMS_ORDER = 'CHANGE_PROGRAMS_ORDER',
  UPDATE_LOAN_AMOUNT_RANGE = 'UPDATE_LOAN_AMOUNT_RANGE',
  ADD_NEW_FICO = 'ADD_NEW_FICO',
  SAVE_RESTRICTION = 'SAVE_RESTRICTION',
  SAVE_PROGRAM_CHANGES = 'SAVE_PROGRAM_CHANGES',
  ADD_RATESHEET_HISTORY = 'ADD_RATESHEET_HISTORY',
  UPDATE_PRODUCT = 'UPDATE_PRODUCT',
  UPDATE_PROGRAM_INFO = 'UPDATE_PROGRAM_INFO',
  UPDATE_RATESHEET_INFO = 'UPDATE_RATESHEET_INFO',
  SYNC_PROGRAM_WITH_GENERAL = 'SYNC_PROGRAM_WITH_GENERAL',
  UPDATE_RANGE = 'UPDATE_RANGE',
  SYNC_TIERS = 'SYNC_TIERS',
  SAVE_COMMERCIAL_PRODUCT_TYPES = 'SAVE_COMMERCIAL_PRODUCT_TYPES',
  UPDATE_DSCR_OCCUPANCY_LTV_REDUCTION = 'UPDATE_DSCR_OCCUPANCY_LTV_REDUCTION',
  CLEAR_RATESHEET_HISTORY = 'CLEAR_RATESHEET_HISTORY',
  LEVERAGE_LIMIT = 'LEVERAGE_LIMIT',
  BONUS_MARGIN = 'BONUS_MARGIN',
  UPDATE_MARGIN = 'UPDATE_MARGIN ',
  UPDATE_EXPERIENCE_TIER_LIMIT = 'UPDATE_EXPERIENCE_TIER_LIMIT',
  ADD_TIER = 'ADD_TIER',
  REMOVE_TIER = 'REMOVE_TIER',
  TIER_VALUES = 'TIER_VALUES',
  MSA_TIER_CONDITION = 'MSA_TIER_CONDITION',
  MODAL_LOADING = 'MODAL_LOADING',
}

export enum MenuItems {
  InvestorInfo = 'Investor Info',
  Margin = 'Price Margin / Rate Limit',
  MaxLTVLimit = 'Max LTV Limits',
  PrepaymentPenalty = 'Prepayment Penalty',
  StateLTVMaxLimit = 'States LTV Limits / Adjustments',
  LeverageLimits = 'Leverage Limits',
  LeverageAdjustments = 'Leverage Adjustments',
  DscrOverlay = 'DSCR Overlay',
  LoanProgram = 'Loan Programs',
}

export enum ProgramTypes {
  BEYOND_PRIME = 'Beyond Prime',
  BEYOND_PRIME_INVESTOR = 'Beyond Prime Investor',
  MULTIFLOW = 'MultiFlow',
  HARD_MONEY = 'HardMoney',
  FAST_MONEY = 'FastMoney',
  SOFT_MONEY = 'SoftMoney',
  COMMERCIAL_ONLY_PROGRAMMED = 'Commercial Only Programmed',
  OTHER = 'Other',
  COMMERCIAL_DSCR = 'CommercialDSCR',
}

export const ProgramContext = createContext<Record<string, any>>({})
export const LoadingContext = createContext<string>('')

export interface IProductsType {
  IsInterestOnly: IProduct[]
  NonInterestOnly: IProduct[]
}

export function RateSheetOverview() {
  const params = useParams()

  if (params.id) useTitle(`${params.id} - Rate Sheet - RTLTrader`)
  else useTitle('General Programs - RTLTrader')

  const [searchParams] = useSearchParams()
  const companyID = searchParams.get('company') || ''

  const navigate = useNavigate()

  const stateData = store.getState()
  const isInvestor = [AccountType.INVESTOR, AccountType.ASSOCIATE].includes(stateData.auth.profile.userType)
  const isAdmin = [AccountType.ADMIN, AccountType.PROGRAMMER].includes(stateData.auth.profile.userType)

  const [loading, setLoading] = useState('')
  const [ratesheet, setRatesheet] = useState<IRateSheet>()
  const [modal, setModal] = useState('')
  const [selectedProgram, setSelectedProgram] = useState<IProgram>()
  const [selectedMenu, setSelectedMenu] = useState<Record<string, string>>()
  const [menus, setMenu] = useState<Record<string, string>[]>([])
  const [restrictionKey, setRestrictionKey] = useState('')
  const [history, setHistory] = useState<IHistory[]>([])
  const [loanId, setLoanId] = useState<number>()
  const [isGeneralPrograms, setIsGeneralPrograms] = useState(false)
  const [selectedTiers, setSelectedTiers] = useState<Record<string, any>>({})
  const [productTypes, setProductTypes] = useState<Record<number, string>>({})
  const [masterProgram, setMasterProgram] = useState<IMasterProgram | undefined>()
  const [lastUpdated, setLastUpdated] = useState(0)
  const [selectedProgramID, setSelectedProgramID] = useState<number>()

  useEffect(() => {
    ;(async () => {
      setLoading(LoadingStatus.GET_RATESHEET)

      let ratesheetData
      let ratesheetID
      let isGeneral = false
      if (params.id && ![0, 1090].includes(Number(params.id))) {
        setLoanId(Number(params.id))
        ratesheetID = Number(params.id)
        ratesheetData = await getRatesheet(Number(params.id))
      } else {
        if (isInvestor) navigate('/ratesheet')
        setIsGeneralPrograms(true)
        isGeneral = true
        ratesheetData = await getRatesheet(0)
        setLoanId(ratesheetData.ratesheet.loanId)
        ratesheetID = ratesheetData.ratesheet.loanId
      }

      if (ratesheetData.ratesheet.programs.length > 0) {
        onSelectProgram(ratesheetData.ratesheet.programs[0], ratesheetID, isGeneral)
        setSelectedProgramID(ratesheetData.ratesheet.programs[0].ID)
      }

      setRatesheet(ratesheetData.ratesheet)
      setHistory(ratesheetData.history)
      setProductTypes(ratesheetData.productTypes)
      setLoading('')
    })()
  }, [params])

  useEffect(() => {
    if (!loanId || !selectedProgramID) return
    ;(async () => {
      setLoading(LoadingStatus.SAVE_PROGRAM_CHANGES)
      const res = await getStateTier(loanId, selectedProgramID)
      setLoading('')
      if (res) setSelectedTiers(res)
      else setSelectedTiers({})
    })()
  }, [selectedProgramID, loanId])

  const onAddNewProgram = async (data: Record<string, any>) => {
    if (!ratesheet) return

    setLoading(LoadingStatus.GET_RATESHEET)
    const res = await addNewProgram(Number(loanId), data)
    await addNewHistory(res.newHistory)

    const nRatesheet = cloneDeep(ratesheet)
    const programs = nRatesheet.programs

    programs.push(res.newProgram)

    nRatesheet.programs = programs

    if (programs.length === 1) await onSelectProgram(res.newProgram)

    const newProductTypes = cloneDeep(productTypes)
    newProductTypes[res.newProgram.ID] = res.newProgram.Type
    setProductTypes(newProductTypes)
    setRatesheet(nRatesheet)
    setModal('')
    setLoading('')
  }

  const onChangeProgramsOrder = async (orderedPrograms: IProgram[]) => {
    if (!ratesheet) return

    setLoading(LoadingStatus.CHANGE_PROGRAMS_ORDER)

    const newOrder: number[] = orderedPrograms.map((item) => item.ID)

    const res = await updateProgramsOrder(Number(loanId), newOrder)

    await addNewHistory(res.newHistory)

    setRatesheet({ ...ratesheet, programs: orderedPrograms })
    setModal('')
    toast('Programs order changed!', { type: 'info' })
    setLoading('')
  }

  const addNewHistory = async (newHistory: IHistory) => {
    const nHistory = cloneDeep(history)

    if (nHistory.length === 50) nHistory.pop()

    nHistory.unshift(newHistory)

    setHistory(nHistory)
  }

  const updateMatrixLTV = async (data: IMatrixLTVData, key: string) => {
    if (!selectedProgram || !selectedProgram?.Restriction) return

    setLoading(LoadingStatus.SAVE_RESTRICTION)

    const res = await updateMatrixLTVLimits(Number(loanId), selectedProgram.ID, data, key)

    const newProgram = cloneDeep(selectedProgram)
    const targetIndex = newProgram.Restriction?.MatrixLTVData?.findIndex((item) => item.key === key)
    if (targetIndex !== undefined && targetIndex !== -1) {
      ;(newProgram as any).Restriction.MatrixLTVData[targetIndex] = data
    }
    setSelectedProgram(newProgram)
    await addNewHistory(res.newHistory)
    setLoading('')
  }

  const onSelectProgram = async (
    item: IProgram,
    ratesheetID: number | undefined = loanId,
    isGeneral: boolean = isGeneralPrograms,
  ) => {
    if (!ratesheetID) return

    setLoading(LoadingStatus.GET_RATESHEET)
    item = (await getProgram(ratesheetID, item.ID)) as IProgram
    setLoading('')
    if (!item) return

    setSelectedProgram(item)

    let menuItems: Record<string, string>[] = []

    if (
      [ProgramTypes.BEYOND_PRIME, ProgramTypes.BEYOND_PRIME_INVESTOR, ProgramTypes.MULTIFLOW].includes(
        item.Type as ProgramTypes,
      )
    ) {
      menuItems.push({ key: 'investorInfo', label: MenuItems.InvestorInfo })
      menuItems.push({ key: 'margin', label: MenuItems.Margin })

      if (item?.Restriction?.MatrixLTVData) menuItems.push({ key: 'maxLTVLimit', label: MenuItems.MaxLTVLimit })

      if (item?.Restriction?.PrepaymentPenalty)
        menuItems.push({ key: 'PrepaymentPenalty', label: item.Restriction.PrepaymentPenalty.label })

      menuItems.push({ key: '', label: MenuItems.LeverageLimits })
      menuItems.push({ key: '', label: MenuItems.LeverageAdjustments })

      if ([ProgramTypes.BEYOND_PRIME_INVESTOR, ProgramTypes.MULTIFLOW].includes(item.Type as ProgramTypes)) {
        menuItems.push({ key: 'StateLTVMaxLimit', label: MenuItems.StateLTVMaxLimit })
        menuItems.push({ key: 'dscrOverlay', label: MenuItems.DscrOverlay })
        if (!isGeneral) menuItems.push({ key: 'loanProgram', label: MenuItems.LoanProgram })
      }

      setMenu(menuItems)
      setSelectedMenu(menuItems[0])

      if (menuItems.length !== 0) setRestrictionKey(menuItems[0].key)
      else setRestrictionKey('')
    } else {
      setMenu([])
      setSelectedMenu({})
      setRestrictionKey('')
    }
  }

  const onClickMenu = (key: string, label: string) => {
    if (!ratesheet) return

    setSelectedMenu({ key: key, label: label })
    setRestrictionKey(key)
  }

  const onSaveLLPA = async (data: IRangeLLPA[] | INormalLLPA[], type: string, key: string, name: string) => {
    if (!selectedProgram) return false

    const payload = {
      programID: selectedProgram.ID,
      llpa: data,
      name: name,
      type: type,
      key: key,
    }

    setLoading(LoadingStatus.UPDATE_RANGE)

    const res = await updateRangeForDscr(payload, Number(loanId))

    await addNewHistory(res.newHistory)
    setSelectedProgram(res.program)
    setLastUpdated(Date.now())
    setLoading('')
  }

  const onSaveProgramChanges = async (data: Record<string, number>) => {
    if (!selectedProgram) return

    setLoading(LoadingStatus.SAVE_PROGRAM_CHANGES)
    const json = {
      ID: selectedProgram.ID,
      data: data,
    }

    const res = await updateProgramData(Number(loanId), json)
    await addNewHistory(res.newHistory)

    setSelectedProgram(res.program)
    toast('Program updated!', { type: 'info' })
    setLoading('')
  }

  const onAddRatesheetHistory = async (program: string, content: string) => {
    setLoading(LoadingStatus.ADD_RATESHEET_HISTORY)

    try {
      const data = {
        program: program,
        content: content,
      }

      const res = await addRatesheetHistory(Number(loanId), data)

      await addNewHistory(res.newHistory)
      setLoading('')
      return true
    } catch (error) {
      console.log(error)
      setLoading('')
      return false
    }
  }

  const onSaveRestriction = async (restriction: any, restricKey: string = '') => {
    if (!selectedProgram) return false

    setLoading(LoadingStatus.SAVE_RESTRICTION)
    try {
      const limitKey = restricKey || restrictionKey

      const data = {
        programID: selectedProgram.ID,
        key: limitKey,
        restriction: restriction,
      }

      const res = await updateProgramRestriction(Number(loanId), data)

      await addNewHistory(res.newHistory)

      let nProgram = cloneDeep(selectedProgram)

      if (limitKey && nProgram.Restriction) (nProgram.Restriction as any)[limitKey] = restriction
      else nProgram.Restriction = restriction

      setSelectedProgram(nProgram)
      setLoading('')

      return true
    } catch (error) {
      console.log(error)
      setLoading('')

      return false
    }
  }

  const onUpdateProducts = async (productId: number) => {
    if (!ratesheet) return

    setLoading(LoadingStatus.UPDATE_PRODUCT)
    const nRatesheet = cloneDeep(ratesheet)
    const prodIndex = nRatesheet.products.findIndex((item) => item.Id === productId)

    nRatesheet.products[prodIndex].Power = !nRatesheet.products[prodIndex].Power

    const res = await updateProductPower(Number(loanId), prodIndex)

    setRatesheet(nRatesheet)
    await addNewHistory(res.newHistory)
    toast(`${nRatesheet.products[prodIndex].Description} - ${nRatesheet.products[prodIndex].Power ? 'On' : 'Off'}`, {
      type: 'info',
    })
    setLoading('')
  }

  const onUpdateProgramInfo = async (index: number, value: any, key: string) => {
    if (!ratesheet) return

    try {
      setLoading(LoadingStatus.UPDATE_PROGRAM_INFO)

      const data = {
        index: index,
        value: value,
        key: key,
      }

      const res = await updateProgramInfo(Number(loanId), data)
      await addNewHistory(res.newHistory)

      const nRatesheet = cloneDeep(ratesheet)

      if (key === 'Name') {
        nRatesheet.programs[index].Name = value

        const changedProgram = cloneDeep(selectedProgram)

        if (!changedProgram) return

        changedProgram.Name = value

        const isSelected = nRatesheet.programs[index].ID === changedProgram.ID

        if (isSelected) setSelectedProgram(changedProgram)
      } else (nRatesheet.programs[index] as any)[key] = value

      setRatesheet(nRatesheet)
      toast('Successfully Updated!', {
        type: 'info',
      })
      setLoading('')

      return true
    } catch (error) {
      console.log(error)
      setLoading('')
      return false
    }
  }

  const onRemoveProgram = async (index: number) => {
    if (!ratesheet) return
    if (!selectedProgram) return

    setLoading(LoadingStatus.UPDATE_PROGRAM_INFO)
    const nRatesheet = cloneDeep(ratesheet)
    const removedProgram = ratesheet.programs.find((item, id: number) => id === index)
    const newPrograms = nRatesheet.programs.filter((item, id: number) => id !== index)

    nRatesheet.programs = newPrograms

    const res = await removeProgram(Number(loanId), index)

    await addNewHistory(res.newHistory)

    const removedProgramIndex = ratesheet.programs.findIndex((item) => item.ID === removedProgram?.ID)

    if (removedProgram?.ID === selectedProgram.ID) {
      if (removedProgramIndex === 0) {
        if (newPrograms.length === 0) {
          setSelectedProgram(undefined)
          setMenu([])
          setSelectedMenu({})
          setRestrictionKey('')
        } else await onSelectProgram(ratesheet.programs[1])
      } else await onSelectProgram(ratesheet.programs[index - 1])
    }

    setRatesheet(nRatesheet)
    toast(`${ratesheet.programs[index].Name} Removed!`, {
      type: 'info',
    })
    setLoading('')
  }

  const onUpdateLockDays = async (index: number) => {
    if (!ratesheet) return

    setLoading(LoadingStatus.UPDATE_RATESHEET_INFO)

    const nRatesheet = cloneDeep(ratesheet)
    nRatesheet.lockDays[index].Power = !nRatesheet.lockDays[index].Power

    const data = {
      lockDays: nRatesheet.lockDays,
    }

    const res = await updateRatesheet(Number(loanId), data)
    await addNewHistory(res.newHistory)

    setRatesheet(nRatesheet)
    setLoading('')
    toast(
      `Lock Days: ${nRatesheet.lockDays[index].description} days - ${nRatesheet.lockDays[index].Power ? 'On' : 'Off'}`,
      {
        type: 'info',
      },
    )
  }

  const onUpdateBankruptcy = async () => {
    if (!ratesheet) return

    setLoading(LoadingStatus.UPDATE_RATESHEET_INFO)

    const nRatesheet = cloneDeep(ratesheet)
    nRatesheet.bankruptcy = !nRatesheet.bankruptcy

    const data = {
      bankruptcy: nRatesheet.bankruptcy,
    }

    const res = await updateRatesheet(Number(loanId), data)
    await addNewHistory(res.newHistory)

    setRatesheet(nRatesheet)
    setLoading('')

    toast('Bankruptcy Updated!', {
      type: 'info',
    })
  }

  const syncProgramWithGeneral = async (data: Record<string, any>) => {
    setLoading(LoadingStatus.SYNC_PROGRAM_WITH_GENERAL)

    try {
      await syncProgram(data)

      toast('Successfully synced!', {
        type: 'success',
      })
      setModal('')
    } catch (error) {
      console.log(error)
    }

    setLoading('')
  }

  const onSyncProgramToGeneral = async () => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to sync this program to General Programs?
        <br />
        <span className="text-gray-600 text-base">
          {ratesheet?.company.name} {`=> General Programs`}
        </span>
        <br />
        <span className="text-gray-600 text-base">Program Name: {selectedProgram?.Name}</span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    setLoading(LoadingStatus.SYNC_PROGRAM_WITH_GENERAL)

    try {
      const reqBody = {
        loanId,
        programID: selectedProgram?.ID,
      }
      await syncProgramToGeneral(reqBody)

      toast('Successfully synced!', {
        type: 'success',
      })
      setModal('')
    } catch (error) {
      console.log(error)
    }

    setLoading('')
  }

  const syncProgramTiersWithGeneral = async (data: Record<string, any>) => {
    setLoading(LoadingStatus.SYNC_TIERS)

    try {
      await syncProgramTiers(data)

      toast('Successfully synced!', {
        type: 'success',
      })
      setModal('')
    } catch (error) {
      console.log(error)
    }

    setLoading('')
  }

  const onSaveLtvRatePriceRangeForNonDscr = async (
    name: string,
    range: IRange[],
    ratePriceAdj: IRatePriceAdjustment,
  ) => {
    if (!selectedProgram) return false

    const data = {
      programID: selectedProgram.ID,
      name: name,
      range: range,
      ratePriceAdj: ratePriceAdj,
    }

    try {
      setLoading(LoadingStatus.UPDATE_RANGE)

      const res = await updateLtvRatePriceRangeForNonDscr(data, Number(loanId))

      await addNewHistory(res.newHistory)
      setSelectedProgram(res.program)
      setLoading('')
      return true
    } catch (error) {
      setLoading('')
      return false
    }
  }

  const onCloneProgram = async (newName: string, originalName: string) => {
    if (!ratesheet) return

    if (!newName) {
      toast('Program Name is Required!', { type: 'error' })
      return
    }

    const isExist = ratesheet.programs.find((item) => item.Name === newName)

    if (isExist) {
      toast('Same name already exists!', { type: 'error' })
      return
    }

    if (!originalName) {
      toast(`Select Program you're cloning`, { type: 'error' })
      return
    }

    const data = {
      newName: newName,
      originalName: originalName,
    }

    setLoading(LoadingStatus.CLONE_PROGRAM)

    const res = await cloneProgram(Number(loanId), data)
    await addNewHistory(res.newHistory)

    const nRatesheet = cloneDeep(ratesheet)
    const programs = nRatesheet.programs

    programs.push(res.newProgram)

    nRatesheet.programs = programs

    if (programs.length === 1) await onSelectProgram(res.newProgram)

    setRatesheet(nRatesheet)

    toast('New Program cloned!', { type: 'success' })

    setModal('')
    setLoading('')
  }

  const onAddStateTier = async (tierName: string, key: string, tierKey: string = '') => {
    setLoading(LoadingStatus.ADD_TIER)

    const data = {
      programID: selectedProgram?.ID,
      tierName: tierName,
      key: key,
      experienceTierKey: tierKey,
    }

    try {
      const res = await addStateTier(data, Number(loanId))

      toast('New Tier has been added', {
        type: 'info',
      })

      await addNewHistory(res.newHistory)

      setSelectedTiers(res.newTiers)
      setLoading('')
      return true
    } catch (error) {
      setLoading('')
      return false
    }
  }

  const onUpdateStateTier = async (originName: string, tierName: string, key: string, tierKey: string = '') => {
    setLoading('editTier')

    const data = {
      programID: selectedProgram?.ID,
      originName: originName,
      tierName: tierName,
      key: key,
      experienceTierKey: tierKey,
    }

    try {
      const res = await updateStateTier(data, Number(loanId))

      toast('Tier Name has been changed', {
        type: 'info',
      })

      await addNewHistory(res.newHistory)

      setSelectedTiers(res.newTiers)
      setLoading('')
      return true
    } catch (error) {
      setLoading('')
      return false
    }
  }

  const onRemoveStateTier = async (tierName: string, key: string, tierKey: string = '') => {
    setLoading(LoadingStatus.REMOVE_TIER)

    const data = {
      programID: selectedProgram?.ID,
      tierName: tierName,
      key: key,
      experienceTierKey: tierKey,
    }

    try {
      const res = await removeStateTier(data, Number(loanId))

      toast(`Successfully Removed`, {
        type: 'info',
      })

      await addNewHistory(res.newHistory)

      setSelectedTiers(res.newTiers)
      setLoading('')
      return true
    } catch (error) {
      setLoading('')
      return false
    }
  }

  const onRemoveMsaLtvAdjCondition = async (tierName: string, index: number) => {
    setLoading(LoadingStatus.MSA_TIER_CONDITION)

    const data = {
      programID: selectedProgram?.ID,
      tierName: tierName,
      index: index,
    }

    try {
      const res = await removeMsaTierLtvAdjCondition(data, Number(loanId))

      toast(`MSA Tier(${tierName})'s condition ${index + 1} has been deleted`, {
        type: 'info',
      })

      await addNewHistory(res.newHistory)

      setSelectedTiers(res.newTiers)
      setLoading('')
      return true
    } catch (error) {
      setLoading('')
      return false
    }
  }

  const onSaveTierValues = async (
    tierName: string,
    key: string,
    state: string,
    values: string[] | IStateMSA[],
    tierKey: string = '',
  ) => {
    setLoading(LoadingStatus.TIER_VALUES)

    const data = {
      programID: selectedProgram?.ID,
      tierName: tierName,
      key: key,
      state: state,
      values: values,
      experienceTierKey: tierKey,
    }

    try {
      const res = await saveStateTierValues(data, Number(loanId))

      toast(
        `${tierName} ${
          key === 'city' ? 'Cities' : key === 'county' ? 'Counties' : key === 'msa' ? 'MSA' : ''
        } Tier updated`,
        {
          type: 'info',
        },
      )

      await addNewHistory(res.newHistory)

      setSelectedTiers(res.newTiers)
      setLoading('')
      return true
    } catch (error) {
      setLoading('')
      return false
    }
  }

  const onSaveLtvAdjustment = async (tierName: string, key: string, ltvAdj: ILoanPurposeLTVValues) => {
    setLoading(LoadingStatus.TIER_VALUES)

    const data = {
      programID: selectedProgram?.ID,
      tierName: tierName,
      key: key,
      ltvAdj: ltvAdj,
    }

    try {
      const res = await saveTierLtvAdjustment(data, Number(loanId))

      toast(
        `${tierName} ${
          key === 'city' ? 'Cities' : key === 'county' ? 'Counties' : key === 'msa' ? 'MSA' : ''
        } Tier LTV Adjustment Updated`,
        {
          type: 'info',
        },
      )

      await addNewHistory(res.newHistory)

      setSelectedTiers(res.newTiers)
      setLoading('')
      return true
    } catch (error) {
      setLoading('')
      return false
    }
  }

  const onSaveMsaLtvAdjustment = async (tierName: string, ltvAdj: IPropertyTypeLtvAdj[]) => {
    setLoading(LoadingStatus.TIER_VALUES)

    const data = {
      programID: selectedProgram?.ID,
      tierName: tierName,
      ltvAdj: ltvAdj,
    }

    try {
      const res = await saveMsaTierLtvAdjustment(data, Number(loanId))

      toast(`MSA Tier(${tierName}) LTV Adjustment Updated`, {
        type: 'info',
      })

      await addNewHistory(res.newHistory)

      setSelectedTiers(res.newTiers)
      setLoading('')
      return true
    } catch (error) {
      setLoading('')
      return false
    }
  }

  const onSaveCommercialProductTypes = async (data: Record<number, string>) => {
    if (isEmpty(data)) {
      setModal('')
      return
    }

    setLoading(LoadingStatus.SAVE_COMMERCIAL_PRODUCT_TYPES)

    try {
      const res = await saveCommercialProductTypes(data, Number(loanId))

      toast(`Product Types Updated`, {
        type: 'info',
      })

      await addNewHistory(res.newHistory)

      const newProductTypes = cloneDeep(productTypes)

      Object.keys(data).forEach((key) => {
        newProductTypes[Number(key)] = data[Number(key)]
      })

      setProductTypes(newProductTypes)
      setModal('')
    } catch (error) {
      console.log(error)
    }

    setLoading('')
  }

  const onUpdateDscrOccupancyLtvReduction = async (value: number) => {
    if (!selectedProgram) return false

    try {
      setLoading(LoadingStatus.UPDATE_DSCR_OCCUPANCY_LTV_REDUCTION)
      const data = {
        Name: selectedProgram?.Name,
        value: value,
      }

      const res = await updateDscrOccupancyLtvReduction(Number(loanId), data)

      await addNewHistory(res.newHistory)

      const newProgram = cloneDeep(selectedProgram)

      if (!newProgram.Restriction) {
        setLoading('')
        return false
      }

      newProgram.Restriction.DscrOccupancyLtvReduction = Number(value)

      setSelectedProgram(newProgram)

      toast(`Occupancy LTV Reduction Updated`, {
        type: 'info',
      })

      setLoading('')
      return true
    } catch (error) {
      setLoading('')
      return false
    }
  }

  const onChangeExperienceLimit = async (tierKey: string, tierName: string, limit: IExperienceTierLimit[]) => {
    try {
      const data = {
        programID: selectedProgram?.ID,
        tierName: tierName,
        tierKey: tierKey,
        limit: limit,
      }

      setLoading(LoadingStatus.UPDATE_EXPERIENCE_TIER_LIMIT)

      const res = await saveExperienceTierLimit(data, Number(loanId))

      toast(`Successfully Updated!`, {
        type: 'success',
      })

      await addNewHistory(res.newHistory)

      setSelectedTiers(res.newTiers)
    } catch (error) {
      console.log(error)
    }
    setLoading('')
  }

  const onClearHistory = async () => {
    const content = <div className="text-gray-400 mb-4 text-[18px]">Do you want to clear Rate Sheet History?</div>

    const result = await confirm(content)

    if (!result) return

    setLoading(LoadingStatus.CLEAR_RATESHEET_HISTORY)

    const res = await clearRatesheetHistory(Number(loanId))

    if (res)
      toast(res.message, {
        type: `${res.result ? 'info' : 'error'}`,
      })

    setLoading('')
  }

  const onChangeNonDscrLeverageData = async (data: any[], dataKey: string, type: string) => {
    if (!selectedProgram) return
    if (!ratesheet) return

    try {
      const newProgram = cloneDeep(selectedProgram)

      const programData = newProgram.OtherTypeProgramData as ICommercialOnlyProgrammed

      ;(programData as any)[type][dataKey] = data
      newProgram.OtherTypeProgramData = programData

      setLoading(LoadingStatus.LEVERAGE_LIMIT)

      const payload = {
        programID: newProgram.ID,
        data: data,
        key: dataKey,
        type: type,
      }

      const res = await saveNonDscrLeverageData(Number(loanId), payload)

      await addNewHistory(res.newHistory)

      setSelectedProgram(newProgram)

      toast(`Successfully updated!`, { type: 'success' })
    } catch (error) {
      console.log(error)
    }

    setLoading('')
  }

  const onChangeDscrLeverageData = async (data: any[], key: string, type: string) => {
    if (!selectedProgram) return false

    setLoading(LoadingStatus.LEVERAGE_LIMIT)
    try {
      const params = {
        programID: selectedProgram.ID,
        type: type,
        key: key,
        data: data,
      }

      const res = await saveDscrLeverageData(Number(loanId), params)

      await addNewHistory(res.newHistory)

      let nProgram = cloneDeep(selectedProgram)

      if (!nProgram?.Restriction) return
      ;(nProgram.Restriction as any)[type][key] = data

      setSelectedProgram(nProgram)
      setLoading('')

      return true
    } catch (error) {
      console.log(error)
      setLoading('')

      return false
    }
  }

  const onSaveProgramInfo = async (data: any, key: string) => {
    if (!selectedProgram || !ratesheet) return

    const newProgram = cloneDeep(selectedProgram)

    ;(newProgram as any)[key] = data

    const programIndex = ratesheet.programs.findIndex((item) => item.ID === selectedProgram.ID)

    if (programIndex !== -1) {
      setSelectedProgram(newProgram)
      await onUpdateProgramInfo(programIndex, data, key)
    }
  }

  const onSaveBonusMargin = async (data: any, key: string) => {
    if (!selectedProgram || !ratesheet) return

    try {
      const newProgram = cloneDeep(selectedProgram)

      if (!newProgram?.BonusMargin) newProgram.BonusMargin = { loanAmount: [] }

      if (!Object.keys(newProgram.BonusMargin).includes(key)) {
        Object.defineProperty(newProgram.BonusMargin, key, {
          value: data,
          writable: true,
        })
      } else (newProgram.BonusMargin as any)[key] = data

      setLoading(LoadingStatus.BONUS_MARGIN)

      const payload = {
        programID: newProgram.ID,
        data: data,
        key: key,
      }

      const res = await saveBonusMargin(Number(loanId), payload)

      await addNewHistory(res.newHistory)

      setSelectedProgram(newProgram)

      toast(`Successfully updated!`, { type: 'success' })
    } catch (error) {
      console.log(error)
    }

    setLoading('')
  }

  const onUpdateMasterProgram = async (data: IMasterProgram) => {
    if (!ratesheet) return

    try {
      setLoading(LoadingStatus.MODAL_LOADING)

      const res = await updateMasterProgram(Number(loanId), data)

      const nRatesheet = cloneDeep(ratesheet)
      if (!nRatesheet?.masterPrograms) nRatesheet.masterPrograms = []
      if (!!data?.ID)
        nRatesheet.masterPrograms.forEach((item) => {
          if (item.ID === data.ID) {
            item.Name = data.Name
            item.Level = data.Level
            item.ProgramIDs = data.ProgramIDs
          }
        })
      else {
        nRatesheet.masterPrograms.push({
          ID: res.ID,
          Name: data.Name,
          Level: data.Level,
          ProgramIDs: data.ProgramIDs,
        })
      }
      setRatesheet(nRatesheet)
      await addNewHistory(res.newHistory)
    } catch (error) {
      console.log(error)
    }

    setLoading('')
    setModal('')
  }

  const onRemoveMasterProgram = async (e: React.MouseEvent<SVGSVGElement, MouseEvent>, id: number, index: number) => {
    e.preventDefault()
    e.stopPropagation()
    if (!ratesheet) return

    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this master program?
        <br />
        <span className="text-gray-600 text-base">Name: {(ratesheet.masterPrograms as any)[index].Name}</span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    try {
      setLoading(LoadingStatus.GET_RATESHEET)

      const res = await removeMasterProgram(Number(loanId), id)

      const nRatesheet = cloneDeep(ratesheet)
      nRatesheet.masterPrograms?.splice(index, 1)
      setRatesheet(nRatesheet)
      await addNewHistory(res.newHistory)
    } catch (error) {
      console.log(error)
    }

    setLoading('')
  }

  const onSaveStaticTable = async (data: IStaticTable[], history: IHistory) => {
    if (!selectedProgram) return

    const newProgram = cloneDeep(selectedProgram)
    newProgram.StaticTable = data
    addNewHistory(history)
  }

  const renderRestriction = useMemo(() => {
    if (!selectedProgram || !ratesheet) return

    switch (selectedMenu?.label) {
      case MenuItems.InvestorInfo:
        return <InvestorInfo program={selectedProgram} loading={loading} onSave={onSaveProgramInfo} />
      case MenuItems.Margin:
        return (
          <ProgramMargin
            program={selectedProgram}
            loading={loading}
            onSaveBonusMargin={onSaveBonusMargin}
            onSavePriceMarginLimit={onSaveProgramInfo}
          />
        )
      case MenuItems.MaxLTVLimit:
        return <MatrixLTVTable program={selectedProgram} loading={loading} onSave={updateMatrixLTV} />
      case MenuItems.PrepaymentPenalty:
        return (
          <PrepaymentPenalty
            title={selectedMenu.label}
            program={selectedProgram}
            loading={loading}
            onSave={onSaveRestriction}
            onSavePPPOverlay={onSaveRestriction}
          />
        )
      case MenuItems.StateLTVMaxLimit:
        return (
          <StateLTVMaxLimit
            isTemplate={ratesheet.isTemp}
            title={selectedMenu.label}
            tiersData={selectedTiers as IStateTier}
            loading={loading as LoadingStatus}
            isGeneralRatesheet={isGeneralPrograms}
            isCommercial={selectedProgram.Type === ProgramTypes.COMMERCIAL_ONLY_PROGRAMMED}
            onAddTier={onAddStateTier}
            onUpdateTier={onUpdateStateTier}
            onRemoveTier={onRemoveStateTier}
            onSaveLtvAdj={onSaveLtvAdjustment}
            onSaveMsaLtvAdj={onSaveMsaLtvAdjustment}
            onSaveTierValues={onSaveTierValues}
            onSyncTiers={(modalName) => setModal(modalName)}
            onRemoveMsaLtvAdjCondition={onRemoveMsaLtvAdjCondition}
          />
        )
      case MenuItems.LeverageLimits:
        return (
          <DscrLeverageLimits
            title={selectedMenu.label}
            loanId={loanId as number}
            isTemplate={ratesheet.isTemp}
            loading={loading}
            program={selectedProgram}
            onSave={onChangeDscrLeverageData}
          />
        )
      case MenuItems.LeverageAdjustments:
        return (
          <DscrLeverageAdjustments
            title={selectedMenu.label}
            loanId={loanId as number}
            isTemplate={ratesheet.isTemp}
            loading={loading}
            program={selectedProgram}
            onSave={onChangeDscrLeverageData}
          />
        )
      case MenuItems.DscrOverlay:
        return (
          <DscrOverlay
            title={selectedMenu.label}
            program={selectedProgram}
            loading={loading}
            onSave={onChangeDscrLeverageData}
          />
        )
      case MenuItems.LoanProgram:
        return <LoanProgram companyID={ratesheet.company.id} program={selectedProgram} />
      default:
        return <></>
    }
  }, [selectedMenu, selectedTiers, loading, selectedProgram])

  const renderExcelSheet = useMemo(() => {
    if (!ratesheet) return
    if (!selectedProgram) return

    switch (selectedProgram.Type) {
      case ProgramTypes.BEYOND_PRIME:
      case ProgramTypes.BEYOND_PRIME_INVESTOR:
      case ProgramTypes.MULTIFLOW:
        return (
          <Dscr
            key={lastUpdated}
            program={selectedProgram}
            loading={loading}
            onSave={onSaveProgramChanges}
            onSaveLLPA={onSaveLLPA}
            onSaveProgramInfo={onSaveProgramInfo}
          />
        )
      case ProgramTypes.HARD_MONEY:
        return <HardMoneyExcelSheet program={selectedProgram} loading={loading} onSave={onSaveProgramChanges} />
      case ProgramTypes.SOFT_MONEY:
        return <SoftMoneyExcelSheet program={selectedProgram} loading={loading} onSave={onSaveProgramChanges} />
      case ProgramTypes.FAST_MONEY:
        return <FastMoneyExcelSheet program={selectedProgram} loading={loading} onSave={onSaveProgramChanges} />
      case ProgramTypes.COMMERCIAL_DSCR:
        return (
          <CommercialDSCRSheet
            program={selectedProgram}
            loading={loading}
            onUpdateRestriction={onSaveRestriction}
            onSave={onSaveProgramChanges}
            onChangeLtvReduction={onUpdateDscrOccupancyLtvReduction}
            onSavePriceMarginLimit={onSaveProgramInfo}
            onSaveBonusMargin={onSaveBonusMargin}
          />
        )
      case ProgramTypes.COMMERCIAL_ONLY_PROGRAMMED:
        return (
          <CommercialOnlyProgrammed
            loanId={loanId as number}
            companyID={ratesheet.company.id}
            program={selectedProgram}
            tiersData={selectedTiers as IStateTier}
            loading={loading as LoadingStatus}
            isTemplate={ratesheet.isTemp}
            isGeneralRatesheet={isGeneralPrograms}
            onSave={onSaveProgramChanges}
            onSaveLtvRatePriceRange={onSaveLtvRatePriceRangeForNonDscr}
            onAddTier={onAddStateTier}
            onUpdateTier={onUpdateStateTier}
            onRemoveTier={onRemoveStateTier}
            onSaveLtvAdj={onSaveLtvAdjustment}
            onSaveMsaLtvAdj={onSaveMsaLtvAdjustment}
            onSaveTierValues={onSaveTierValues}
            onSyncTiers={(modalName) => setModal(modalName)}
            onRemoveMsaLtvAdjCondition={onRemoveMsaLtvAdjCondition}
            onChangeExperienceLimit={onChangeExperienceLimit}
            onChangeNonDscrLeverageData={onChangeNonDscrLeverageData}
            onSaveBonusMargin={onSaveBonusMargin}
            onSaveProgramInfo={onSaveProgramInfo}
          />
        )
      default: {
        return (
          <span className="flex justify-center w-full my-4">
            <img src={svgLoading} className="inline w-10 h-10 text-white animate-spin" />
          </span>
        )
      }
    }
  }, [selectedProgram, selectedTiers, loading, ratesheet, isGeneralPrograms, loanId, lastUpdated])

  const renderProducts = useMemo(() => {
    if (!ratesheet) return

    const armProducts: IProductsType = { IsInterestOnly: [], NonInterestOnly: [] }
    const fixedProducts: IProductsType = { IsInterestOnly: [], NonInterestOnly: [] }

    ratesheet.products.forEach((item, index: number) => {
      if (item.Type === 'ARM') {
        if (item.IsInterestOnly) armProducts.IsInterestOnly.push({ ...item, index: index })
        else armProducts.NonInterestOnly.push({ ...item })
      } else if (item.Type === 'Fixed') {
        if (item.IsInterestOnly) fixedProducts.IsInterestOnly.push({ ...item, index: index })
        else fixedProducts.NonInterestOnly.push({ ...item })
      }
    })

    const products = {
      armProducts: armProducts,
      fixedProducts: fixedProducts,
    }

    return <RatesheetProducts products={products} loading={loading} onSave={onUpdateProducts} />
  }, [ratesheet, selectedProgram, loading])

  return (
    <div className="relative py-6 px-2">
      <LayoutLoading show={loading === LoadingStatus.GET_RATESHEET} />
      <div className="relative shadow1 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 pb-3 md:pb-3 sm:text-center lg:text-left w-full mb-4">
        {!isGeneralPrograms && (
          <div className="text-shade-blue flex items-center mb-2 font-variation-settings-600">
            <ChevronLeftIcon className="w-4 h-4"></ChevronLeftIcon>
            <Link to={`/ratesheet${companyID ? '?company=' + companyID : ''}`} className="hover:underline">
              Return to Rate Sheets
            </Link>
          </div>
        )}
        <h1 className="text-2xl font-variation-settings-600 mb-2">
          <span>{isGeneralPrograms ? 'General Programs' : ratesheet?.isTemp ? 'Template' : 'Rate Sheet Overview'}</span>
        </h1>

        <div className="flex justify-between items-center mb-4 h-5">
          <div className="text-base font-variation-settings-600 flex items-center gap-2">
            {isGeneralPrograms ? '' : ratesheet?.isTemp ? ratesheet.company.name : `Rate Sheet ID : ${loanId}`}
            <a
              href={`${process.env.REACT_APP_API_URL}/ratesheet/download/${loanId}?token=${stateData.auth.token}`}
              download
              className="p-1 hover-shadow1 cursor-pointer rounded transition-all duration-200"
            >
              <Tooltip content="Download Excel">
                <InboxArrowDownIcon className="w-5 h-5 text-shade-blue" />
              </Tooltip>
            </a>

            <Tooltip content="Change Programs Order">
              <div
                className="p-1 hover-shadow1 cursor-pointer rounded transition-all duration-200"
                onClick={() => setModal(ModalSetting.CHANGE_PROGRAMS_ORDER)}
              >
                <ListBulletIcon className="w-5 h-5 text-shade-blue" />
              </div>
            </Tooltip>

            {ratesheet && !isGeneralPrograms && (
              <div
                className="p-1 hover-shadow1 cursor-pointer rounded transition-all duration-200"
                onClick={() => setModal(ModalSetting.EDIT_PRODUCT_TYPE)}
              >
                <Tooltip content={`${ratesheet.isTemp ? 'Edit Product Type' : 'View Product Type'}`}>
                  <PencilSquareIcon className="w-5 h-5 text-shade-blue" />
                </Tooltip>
              </div>
            )}
          </div>

          {isGeneralPrograms && (
            <Button size="sm" onClick={() => setModal(ModalSetting.SYNC_PROGRAM_WITH_GENERAL)}>
              <div className="flex justify-center items-center gap-1">
                <ArrowPathIcon className="w-4 h-4" />
                <span>Sync</span>
              </div>
            </Button>
          )}
        </div>

        <div>
          {!isGeneralPrograms && (
            <>
              {!!ratesheet?.masterPrograms && (
                <>
                  <div>
                    <div className="text-xl font-variation-settings-600">Master Programs (DSCR)</div>

                    <div className="flex flex-wrap items-center">
                      {ratesheet &&
                        ratesheet.masterPrograms.map((item, index) => (
                          <Button
                            size="sm"
                            key={index}
                            className="mb-0 my-2 py-[5px] px-[10px] transition-all duration-200"
                            color={masterProgram?.Name === item.Name ? 'green' : 'gray'}
                            onClick={() => {
                              setMasterProgram(item)
                              setModal(ModalSetting.MASTER_PROGRAM)
                            }}
                          >
                            <div className="flex items-center justify-center gap-1">
                              <TrashIcon
                                className="w-4 h-4 hover:text-red-700"
                                onClick={(e) => onRemoveMasterProgram(e, item.ID, index)}
                              />
                              <span>{item.Name}</span>
                            </div>
                          </Button>
                        ))}

                      <Button
                        size="sm"
                        className="mb-0 my-2 py-[5px] px-[10px]"
                        color="gray"
                        onClick={() => {
                          setMasterProgram(undefined)
                          setModal(ModalSetting.MASTER_PROGRAM)
                        }}
                      >
                        <PlusIcon className="w-5 h-5" />
                      </Button>
                    </div>
                  </div>

                  <hr className="my-4" />
                </>
              )}

              <div className="flex gap-4 items-center justify-between">
                {!!ratesheet?.masterPrograms && (
                  <span className="text-xl font-variation-settings-600">Sub Programs</span>
                )}
                {isAdmin && selectedProgram && !isGeneralPrograms && ratesheet?.isTemp && (
                  <Button size="sm" onClick={onSyncProgramToGeneral}>
                    <div className="flex justify-center items-center gap-1">
                      <ArrowPathIcon className="w-4 h-4" />
                      <span>Sync To General</span>
                    </div>
                  </Button>
                )}
              </div>
            </>
          )}
          <div className="flex flex-wrap items-center">
            {ratesheet &&
              ratesheet.programs.map((item, index) => (
                <Button
                  size="sm"
                  key={index}
                  className="mb-0 my-2 py-[5px] px-[10px] transition-all duration-200"
                  color={selectedProgram?.Name === item.Name ? 'green' : 'gray'}
                  onClick={() => {
                    onSelectProgram(item)
                    setSelectedProgramID(item.ID)
                  }}
                >
                  {item.Name}
                </Button>
              ))}

            {ratesheet && ratesheet.isTemp && (
              <>
                {ratesheet.programs.length > 0 && (
                  <Button
                    size="sm"
                    className="mb-0 my-2 py-[5px] px-[10px]"
                    color="gray"
                    onClick={() => setModal(ModalSetting.CLONE_PROGRAM)}
                  >
                    <Square2StackIcon className="w-5 h-5" />
                  </Button>
                )}
                <Button
                  size="sm"
                  className="mb-0 my-2 py-[5px] px-[10px]"
                  color="gray"
                  onClick={() => setModal(ModalSetting.NEW_PROGRAM)}
                >
                  <PlusIcon className="w-5 h-5" />
                </Button>
              </>
            )}
          </div>
        </div>
      </div>

      {!!menus.length && (
        <div className="py-3">
          <div className="relative max-w-screen-2xl m-auto grid grid-cols-12 gap-6 w-full">
            <div className="col-span-12 md:col-span-3 shrink-0 p-4 bg-white shadow1 rounded h-full">
              <ul className="flex flex-col">
                {menus.map((item, index) => {
                  return (
                    <li key={index} onClick={() => onClickMenu(item.key, item.label)} className="border-b py-2">
                      <p
                        className={`hover:underline cursor-pointer ${
                          selectedMenu?.label === item.label ? 'border px-4 py-1 bg-zinc-100' : 'py-1'
                        }`}
                      >
                        {index + 1 + '. ' + item.label}
                      </p>
                    </li>
                  )
                })}
              </ul>
            </div>
            <LoadingContext.Provider value={loading}>
              <ProgramContext.Provider value={{ selectedProgram, setSelectedProgram }}>
                <div className="col-span-12 md:col-span-9">
                  <div className="relative bg-white p-4 rounded shadow1 mb-5 h-full">{renderRestriction}</div>
                </div>
              </ProgramContext.Provider>
            </LoadingContext.Provider>
          </div>
        </div>
      )}

      {selectedProgram && (
        <div className="relative">
          <LayoutLoading show={loading === LoadingStatus.SAVE_PROGRAM_CHANGES} />
          {renderExcelSheet}
        </div>
      )}

      {selectedProgram && ratesheet && (
        <StaticTable loanId={ratesheet.loanId} program={selectedProgram} loading={loading} onSave={onSaveStaticTable} />
      )}

      {!isGeneralPrograms && ratesheet && ratesheet.products.length > 0 && (
        <div className="relative max-w-screen-xl m-auto w-full mb-5 shadow1 rounded pt-4 pb-5 px-4">
          <LayoutLoading show={loading === LoadingStatus.UPDATE_PRODUCT} />
          <p className="border-b text-lg font-variation-settings-600 mb-2">Products</p>
          {renderProducts}
        </div>
      )}

      {!isGeneralPrograms && ratesheet && (
        <LockDaysBankruptcy
          ratesheet={ratesheet}
          loading={loading}
          onChangeLockDays={onUpdateLockDays}
          onUpdateBankruptcy={onUpdateBankruptcy}
        />
      )}

      {ratesheet && ratesheet.programs.length !== 0 && (
        <RatesheetPrograms
          programs={ratesheet.programs}
          loading={loading}
          isGeneralRatesheet={isGeneralPrograms}
          onUpdate={onUpdateProgramInfo}
          onDelete={onRemoveProgram}
        />
      )}

      {history && ratesheet && (
        <div className="relative max-w-screen-xl m-auto w-full">
          <RatesheetHistory
            history={history}
            ratesheet={ratesheet}
            loading={loading}
            onAdd={onAddRatesheetHistory}
            onClear={onClearHistory}
          />
        </div>
      )}

      {modal === ModalSetting.NEW_PROGRAM && (
        <NewProgramModal
          isOpen={modal === ModalSetting.NEW_PROGRAM}
          loading={loading}
          onClose={() => setModal('')}
          onSubmit={onAddNewProgram}
        />
      )}
      {modal === ModalSetting.CLONE_PROGRAM && ratesheet && (
        <CloneProgramModal
          programs={ratesheet.programs}
          isOpen={modal === ModalSetting.CLONE_PROGRAM}
          loading={loading}
          onClose={() => setModal('')}
          onSubmit={onCloneProgram}
        />
      )}
      {modal === ModalSetting.CHANGE_PROGRAMS_ORDER && (
        <ProgramsOrderModal
          isOpen={modal === ModalSetting.CHANGE_PROGRAMS_ORDER}
          loading={loading}
          programs={ratesheet?.programs}
          onClose={() => setModal('')}
          onSubmit={onChangeProgramsOrder}
        />
      )}
      {modal === ModalSetting.SYNC_PROGRAM_WITH_GENERAL && (
        <SyncProgramModal
          isOpen={modal === ModalSetting.SYNC_PROGRAM_WITH_GENERAL}
          loading={loading}
          program={selectedProgram}
          onClose={() => setModal('')}
          onSubmit={syncProgramWithGeneral}
        />
      )}
      {modal === ModalSetting.SYNC_TIERS && selectedProgram && (
        <SyncTiersModal
          isOpen={modal === ModalSetting.SYNC_TIERS}
          loading={loading}
          program={selectedProgram}
          onClose={() => setModal('')}
          onSubmit={syncProgramTiersWithGeneral}
        />
      )}
      {modal === ModalSetting.EDIT_PROGRAM_INFO && (
        <EditProgramInfoModal
          isOpen={modal === ModalSetting.EDIT_PROGRAM_INFO}
          loading={loading}
          onClose={() => setModal('')}
          onSubmit={() => {}}
        />
      )}
      {modal === ModalSetting.EDIT_PRODUCT_TYPE && ratesheet && (
        <EditProductTypeModal
          isTemplate={ratesheet.isTemp}
          programs={ratesheet.programs}
          data={productTypes}
          loading={loading}
          isOpen={modal === ModalSetting.EDIT_PRODUCT_TYPE}
          onClose={() => setModal('')}
          onSubmit={onSaveCommercialProductTypes}
        />
      )}
      {modal === ModalSetting.MASTER_PROGRAM && ratesheet && (
        <MasterProgramModal
          masterProgram={masterProgram}
          programs={ratesheet.programs}
          productTypes={productTypes}
          loading={loading}
          isOpen={modal === ModalSetting.MASTER_PROGRAM}
          onClose={() => {
            setModal('')
            setMasterProgram(undefined)
          }}
          onSubmit={onUpdateMasterProgram}
        />
      )}
    </div>
  )
}
